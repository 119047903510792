import React from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  CardActions,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  Edit,
  ExportButton,
  Filter,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  RefreshButton,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  TabbedForm,
  FormTab,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  BooleanField,
  NumberField
} from "react-admin";
import { PapertrailReferenceTable } from "../components/PapertrailReferenceTable";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);
const SchoolCustomersFilters = props => <Filter {...props}></Filter>;

export const SchoolCustomerList = props => (
  <List
    {...props}
    filters={<SchoolCustomersFilters />}
    sort={{ field: "createdAt", order: "DESC" }}
    actions={<PostActions />}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField source="customer" />
      <TextField source="school" />
      <DateField label="Created date" source="createdAt" locales="en-GB" />
    </Datagrid>
  </List>
);

export const SchoolCustomerEdit = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="General">
        <TextInput label="Id" source="id" disabled />
        <ReferenceField
          label="Customer"
          source="customer"
          reference="customers"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="School" source="school" reference="schools">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="marketingOptIn" />
        <hr />
        <NumberField source="numberOfPurchases.bookings" />
        <NumberField source="numberOfPurchases.giftCards" />
        <NumberField source="numberOfPurchases.products" />
        <NumberField source="numberOfPurchases.total" />
        <hr />
        <NumberField source="totalSpend.bookings" />
        <NumberField source="totalSpend.giftCards" />
        <NumberField source="totalSpend.products" />
        <NumberField source="totalSpend.total" />
        <DateField label="Created Date" source="createdAt" locales="en-GB" />
        <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
      </FormTab>
    </TabbedForm>
  </Edit>
);
