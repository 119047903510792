import React from "react";
import {
  ArrayField,
  AutocompleteInput,
  BooleanInput,
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  ExportButton,
  Filter,
  FormTab,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  RefreshButton,
  SelectField,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  SimpleFormIterator,
  ArrayInput
} from "react-admin";
import CourseditActions from "./../actions/courseEditActions";
import { PapertrailReferenceTable } from "../components/PapertrailReferenceTable";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);
const SessionFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by ID" source="qID" />
  </Filter>
);

export const SessionList = props => (
  <List
    {...props}
    filters={<SessionFilter />}
    sort={{ field: "updatedAt", order: "DESC" }}
    actions={<PostActions />}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <DateField label="Date" source="startDateTime" locales="en-GB" />
      <NumberField label="Availability" source="availability" />
      <NumberField label="Total availability" source="totalAvailability" />
      <NumberField label="Obby users" source="obbyUsers" />
      <NumberField label="Non Obby Users" source="nonObbyUsers" />
    </Datagrid>
  </List>
);

export const SessionEdit = props => (
  <Edit {...props} actions={<CourseditActions />}>
    <TabbedForm>
      <FormTab label="Summary">
        <TextInput disabled label="ID" source="id" />
        <ReferenceField label="Course" source="courseID" reference="courses">
          <TextField source="title" />
        </ReferenceField>
        <BooleanInput label="Is session for online?" source="isOnline" />
        <SelectInput
          label="Visibility"
          source="visibility"
          choices={[
            { id: "public", name: "public" },
            { id: "early_access", name: "early_access" }
          ]}
        />
        <TextInput source="sessionType" />
        <ReferenceInput
          label="Course"
          source="courseID"
          reference="courses"
          perPage={5}
          filterToQuery={searchText => ({ qTitle: searchText })}
        >
          <AutocompleteInput
            optionText="title"
            allowEmpty={true}
            shouldRenderSuggestions={val => {
              return val.trim().length >= 2;
            }}
            highlightFirstSuggestion={false}
            inputValueMatcher={() => null}
            options={{
              fullWidth: true,
              suggestionsContainerProps: {
                disablePortal: false
              }
            }}
          />
        </ReferenceInput>
        <BooleanInput source="notified" />
        <BooleanInput source="cancelled" />
        <BooleanInput source="deleted" />
        <BooleanInput
          label="Hide from Algolia Results?"
          source="hiddenFromResults"
        />
        <DateField label="Created date" source="createdAt" locales="en-GB" />
        <DateField label="Updated date" source="updatedAt" locales="en-GB" />
      </FormTab>
      <FormTab label="Availability">
        <NumberInput source="duration" />
        <NumberInput source="availability" />
        <NumberInput source="totalAvailability" />
        <NumberInput source="obbyUsers" />
        <NumberInput source="nonObbyUsers" />
      </FormTab>
      <FormTab label="Price">
        <ArrayField source="ticketTypes">
          <Datagrid>
            <NumberField source="maxLimit" />
            <NumberField source="currentSold" />
            <ReferenceField
              label="Ticket Type"
              source="ticketTypeId"
              reference="ticketTypes"
            >
              <TextField source="name" />
            </ReferenceField>
            <SelectField
              label="Status"
              source="status"
              choices={[
                { id: "enabled", name: "enabled" },
                { id: "disabled", name: "disabled" }
              ]}
            />
          </Datagrid>
        </ArrayField>
        <NumberInput source="price" />
        <NumberInput source="childPrice" />
        <NumberInput source="studentPrice" />
      </FormTab>
      <FormTab label="Location and Time">
        <DateTimeInput label="Date" source="startDateTime" />
        <BooleanInput source="weekend" />
        <TextInput
          label="Location ID raw (put -1 to be anywhere)"
          source="locationId"
        />
        <ReferenceInput
          label="Location Name"
          source="locationId"
          reference="partnerLocations"
          perPage={5}
          filterToQuery={searchText => ({ q: searchText })}
        >
          <AutocompleteInput
            optionText="name"
            allowEmpty={true}
            shouldRenderSuggestions={val => {
              return val.trim().length >= 2;
            }}
            highlightFirstSuggestion={false}
            inputValueMatcher={() => null}
            options={{
              fullWidth: true,
              suggestionsContainerProps: {
                disablePortal: false
              }
            }}
          />
        </ReferenceInput>
        <SelectInput
          label="Time Of Day"
          source="timeOfDay"
          choices={[
            { id: "Evening", name: "Evening" },
            { id: "Daytime", name: "Daytime" }
          ]}
        />
      </FormTab>
      <FormTab label="Additional Classes">
        <ArrayInput source="additionalClasses" label="">
          <SimpleFormIterator>
            <ReferenceField
              label="Session Id"
              source="sessionId"
              reference="sessions"
            >
              <TextField source="_id" />
            </ReferenceField>
            <TextField source="number" label="Number" />
            <DateTimeInput
              source="startDateTime"
              label="Start Date Time"
              showTime
              locales="en-gb"
            />
            <NumberInput source="duration" label="Duration (min)" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="zoom">
        <TextInput
          label="Teacher Zoom User id "
          source="onlineProviderMeeting.host_id"
        />
        <DateField
          label="Meeting startin time"
          source="onlineProviderMeeting.start_time"
          locales="en-GB"
        />
        <TextInput
          label="Zoom Start URL"
          source="onlineProviderMeeting.start_url"
        />
        <TextInput
          label="Zoom Join URL"
          source="onlineProviderMeeting.join_url"
        />
      </FormTab>

      <FormTab label="Papertrail">
        <PapertrailReferenceTable />
      </FormTab>
    </TabbedForm>
  </Edit>
);
