import React from "react";
import {
  Datagrid,
  DateField,
  FunctionField,
  ReferenceManyField,
  TextField
} from "react-admin";

export function PapertrailReferenceTable() {
  return (
    <ReferenceManyField
      reference="papertrail"
      target="qItemId"
      label=""
      // fullWidth
    >
      <Datagrid rowClick="edit">
        <TextField source="actionType" label="Action" />
        <TextField source="actionText" label="" />
        <FunctionField
          label="User"
          render={record => {
            if (record.userType === "user")
              return (
                <a
                  href={`/#/users/${record.userId}`}
                  onClick={e => e.stopPropagation()}
                >
                  {record.userEmail}
                </a>
              );
            if (record.userType === "obby")
              return (
                <>
                  <a
                    href={`/#/adminUsers/${record.userId}`}
                    onClick={e => e.stopPropagation()}
                  >
                    {record.userEmail}
                  </a>{" "}
                  (internal)
                </>
              );
            return (
              <span>
                {record.userType} #{record.userId}
              </span>
            );
          }}
        />
        <DateField
          label="Date"
          source="createdAt"
          locales="en-GB"
          sortable
          options={{
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "Europe/London"
          }}
        />
      </Datagrid>
    </ReferenceManyField>
  );
}
