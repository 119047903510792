import React from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  CardActions,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  Edit,
  ExportButton,
  Filter,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  RefreshButton,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  TabbedForm,
  FormTab,
  ReferenceArrayField,
  SingleFieldList,
  ChipField
} from "react-admin";
import { PapertrailReferenceTable } from "../components/PapertrailReferenceTable";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);
const CustomerFilters = props => (
  <Filter {...props}>
    <TextInput label="Search by name" source="qName" />
    <TextInput label="Search by Used By (email)" source="qUsedEmail" alwaysOn />
    <TextInput label="Search by createdBy" source="qCreatedBy" />
  </Filter>
);

export const CustomerList = props => (
  <List
    {...props}
    filters={<CustomerFilters />}
    sort={{ field: "createdAt", order: "DESC" }}
    actions={<PostActions />}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField source="email" />
      <TextField label="name" source="name" />
      <DateField label="Created date" source="createdAt" locales="en-GB" />
    </Datagrid>
  </List>
);

export const CustomerEdit = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="General">
        <TextInput label="Id" source="id" disabled />
        <TextField source="email" />
        <TextField source="name" />
        <TextField source="phone" />
        <ReferenceArrayField
          label="School Linked"
          reference="schoolCustomers"
          source="schoolBindings"
        >
          <SingleFieldList>
            <ChipField label="School Customer ID" source="_id" />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField label="Created Date" source="createdAt" locales="en-GB" />
        <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
      </FormTab>
    </TabbedForm>
  </Edit>
);
