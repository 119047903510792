import React, { useState } from "react";
import { connect } from "react-redux";
import { change, submit, isSubmitting } from "redux-form";
import {
  fetchEnd,
  fetchStart,
  showNotification,
  crudGetMatching,
  Button,
  SaveButton,
  CREATE
} from "react-admin";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import dataProvider from "../dataProvider";

function ProductHideAction(props) {

  const [showDialog,setShowDialog] = useState(false)

  function onHide(){
    setShowDialog(true);
  }

  function onClose(){
    setShowDialog(false);
  }

  function onSubmit(){
    // This is needed because our modal action buttons are oustide the form
    const { fetchStart, fetchEnd, showNotification } = props;
    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();
    dataProvider(CREATE,  `actions/products/${props.data.id}/hide`,{
      data: {hidden: !props.data.hidden}})
      .then(() => {
        setShowDialog( false);
        //hacky to see the effects
        window.location.reload();
      })
      .catch(error => {
        showNotification(error.message, "error");
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  }

  const { isSubmitting } = props;
  const{hidden = false} = props.data || {};

  return (
    <>
      <Button onClick={onHide} label={`${hidden ? "Expose":"Hide"} Product?`}/>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={onClose}
        aria-label="Create post"
      >
        <DialogTitle>Do you want to {hidden ? "expose" : "hide"} this product?</DialogTitle>
        <DialogActions>
          <SaveButton
            label="Yes,i am sure."
            saving={isSubmitting}
            handleSubmitWithRedirect={onSubmit}
          />
          <Button label="Cancel" onClick={onClose}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting("post-quick-create")(state)
});

const mapDispatchToProps = {
  change,
  crudGetMatching,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductHideAction);
