import React, { Component } from "react";
import { CardActions } from "react-admin";
import ApproveCourse from "./ApproveCourse";
import ArchiveCourse from "./ArchiveCourse";
import DeleteCourseAnytime from "./DeleteCourseAnytime";
import DeleteCourseBlockBooking from "./DeleteCourseBlockBooking";
import EmergencyZoomReset from "./EmergencyZoomReset";
import FixAvailabilityCourse from "./FixAvailabilityCourse";
import HideCourse from "./HideCourse";
import ResetAlgoliaCourse from "./ResetAlgoliaCourse";

class CourseditActions extends Component {
  render() {
    return (
      <CardActions>
        <DeleteCourseAnytime data={this.props.data} />
        <DeleteCourseBlockBooking data={this.props.data} />
        <EmergencyZoomReset data={this.props.data} />
        <FixAvailabilityCourse data={this.props.data} />
        <ResetAlgoliaCourse data={this.props.data} />
        <ArchiveCourse data={this.props.data} />
        <HideCourse data={this.props.data} />
        <ApproveCourse data={this.props.data} />
      </CardActions>
    );
  }
}
export default CourseditActions;
