import React from "react";
import { CardActions } from "react-admin";

import ResendUserEmailVerification from "./ResendUserEmailVerification";

export default function UserEditActions(props) {
  const { data: user } = props;
  if (!user) return <></>;

  return (
    <CardActions>
      <ResendUserEmailVerification data={user} />
    </CardActions>
  );
}
