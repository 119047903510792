import React, { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import { axios } from "../config/axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useRefresh } from "react-admin";

import {
  Button,
  fetchEnd,
  fetchStart,
  SaveButton,
  useNotify
} from "react-admin";

export function SelectBundleDateButton({
  record: bundle // { _id: bundleId, bundleRecords, bundleTotalTickets }
}) {
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();

  const [selectedSessions, setSelectedSessions] = useState([]);
  const [data, setData] = useState({
    sessions: [],
    school: undefined
  });
  const { sessions, school } = data;

  const [state, setState] = useState({
    isSubmitting: false,
    showDialog: false
  });
  const { isSubmitting, showDialog } = state;

  function onCloseDialog() {
    setState({ ...state, showDialog: false });
  }

  async function onSelectDate() {
    setState({ ...state, showDialog: true, sessions: [] });

    const response = await axios.get(
      `/actions/bookings/${bundle._id}/bundle-dates`
    );
    const { sessions, school } = response.data;

    setData({ sessions, school });
    const selectedSessions = bundle.bundleRecords.map(record => record.session);
    // setAlreadySelectedSessions(alreadySelectedSessions);
    setSelectedSessions(selectedSessions);
  }

  function getLabel(session) {
    const { timezone = "Europe/London" } = school;
    const startDateTime = moment(session.startDateTime).tz(timezone);
    const endDateTime = startDateTime.clone().add(session.duration, "minutes");

    return (
      <>
        {startDateTime.format("ddd Do MMMM h:mma")} -{" "}
        {endDateTime.format("h:mma z")}{" "}
        <small>
          (
          {session.availability === 0
            ? "sold out"
            : `${session.availability} spots available`}
          )
        </small>
      </>
    );
  }
  function getGroupedSessions() {
    return sessions.reduce((groupedSessions, session) => {
      const { timezone = "Europe/London" } = school;
      const monthYear = moment(session.startDateTime)
        .tz(timezone)
        .format("MMMM YYYY");
      if (!groupedSessions[monthYear]) groupedSessions[monthYear] = [];
      groupedSessions[monthYear].push(session);
      return groupedSessions;
    }, {});
  }

  function isSessionDisabled(session) {
    // if this session belongs to selected alwasy make available to be disabled
    if (selectedSessions.includes(session._id)) return false;
    // no spots available
    if (session.availability === 0) return true;
    // all tickets are selected
    if (
      bundle.bundleTotalTickets === selectedSessions.length &&
      !selectedSessions.includes(session._id)
    )
      return true;
    return false;
  }

  function isSubmitDisabled() {
    return (
      selectedSessions.length === 0 ||
      (selectedSessions.length === bundle.bundleRecords.length &&
        bundle.bundleRecords.every(record =>
          selectedSessions.includes(record.session)
        ))
    );
  }

  function onChange(sessionId, checked) {
    if (checked && !selectedSessions.includes(sessionId))
      setSelectedSessions([...selectedSessions, sessionId]);

    if (!checked && selectedSessions.includes(sessionId))
      setSelectedSessions(
        selectedSessions.filter(
          selectedSessionId => selectedSessionId !== sessionId
        )
      );
  }

  async function onSubmit() {
    dispatch(fetchStart());

    try {
      setState({ ...state, isSubmitting: true });
      const { data } = await axios.post(
        `/actions/bookings/${bundle._id}/bundle-dates`,
        {
          sessionsIds: selectedSessions
        }
      );
      setSelectedSessions(data.bundleRecords.map(record => record.session));
      setState({
        ...state,
        isSubmitting: false,
        showDialog: false
      });
      notify("Dates successfully selected", "success");
      refresh();
    } catch (e) {
      notify(e.message, "error");
      setState({ ...state, isSubmitting: false });
    } finally {
      dispatch(fetchEnd());
    }
  }

  return (
    <>
      <Button onClick={onSelectDate} label="Select Date" />
      <Dialog fullWidth open={showDialog} onClose={onCloseDialog}>
        <DialogTitle>
          Select Bundle Dates{" "}
          {bundle && (
            <small>
              ({selectedSessions.length} of {bundle.bundleTotalTickets}{" "}
              selected)
            </small>
          )}
        </DialogTitle>
        <DialogContent>
          {Object.entries(getGroupedSessions()).map(([date, sessions]) => (
            <FormControl key={date}>
              <FormLabel>
                <b>{date}</b>
              </FormLabel>
              <FormGroup>
                {sessions.map(session => (
                  <FormControlLabel
                    key={session._id}
                    control={
                      <Checkbox
                        disabled={isSessionDisabled(session)}
                        checked={selectedSessions.includes(session._id)}
                        onChange={(e, checked) =>
                          onChange(session._id, checked)
                        }
                      />
                    }
                    label={getLabel(session)}
                  />
                ))}
              </FormGroup>
            </FormControl>
          ))}
        </DialogContent>
        <DialogActions>
          <SaveButton
            label="Submit"
            saving={isSubmitting}
            disabled={isSubmitDisabled() || isSubmitting}
            handleSubmitWithRedirect={onSubmit}
            icon={<></>}
          />
          <Button label="Cancel" onClick={onCloseDialog} />
        </DialogActions>
      </Dialog>
    </>
  );
}
