import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconCancel from "@material-ui/icons/Cancel";
import React, { Component, Fragment } from "react";
import {
    Button, CREATE, crudGetMatching, fetchEnd,
    fetchStart, SaveButton, showNotification
} from "react-admin";
import { connect } from "react-redux";
import { change, isSubmitting, submit } from "redux-form";
import dataProvider from "../dataProvider";

class ArchiveCourse extends Component {
  state = {
    error: false,
    showDialog: false
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleSaveClick = () => {
    // This is needed because our modal action buttons are oustide the form
    const { fetchStart, fetchEnd, showNotification } = this.props;
    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();
    dataProvider(CREATE, "actions/course/adminArchiveCourse", {
      data: {
        courseID: this.props.data.id
      }
    })
      .then(() => {
        this.setState({ showDialog: false });
        //hacky to see the effects
        window.location.reload();
      })
      .catch(error => {
        showNotification(error.message, "error");
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  render() {
    const { showDialog } = this.state;
    const { isSubmitting } = this.props;
    return (
      <Fragment>
        <Button onClick={this.handleClick} label="Archive class?"></Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Create post"
        >
          <DialogTitle>Archive class?</DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <SaveButton
              label="Yes,i am sure."
              saving={isSubmitting}
              handleSubmitWithRedirect={this.handleSaveClick}
            />
            <Button label="Cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting("post-quick-create")(state)
});

const mapDispatchToProps = {
  change,
  crudGetMatching,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveCourse);
