import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { axios } from "../config/axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import {
  Button,
  fetchEnd,
  fetchStart,
  SaveButton,
  useNotify
} from "react-admin";

export function MarkSchoolAsSoldOutButton({ school }) {
  const dispatch = useDispatch();

  const notify = useNotify();

  const [state, setState] = useState({
    mark: true,
    isSubmitting: false,
    showDialog: false
  });
  const { mark, isSubmitting, showDialog } = state;

  function onCloseDialog() {
    setState({ ...state, showDialog: false });
  }

  function onChange(e) {
    setState({
      ...state,
      mark: e.target.value
    });
  }

  function onOpenDialog() {
    setState({ ...state, showDialog: true });
  }

  async function onSubmit() {
    dispatch(fetchStart());

    try {
      setState({ ...state, isSubmitting: true });
      const { data } = await axios.post(
        `/actions/schools/${school._id}/mark-as-sold-out`,
        {
          mark
        }
      );
      setState({
        ...state,
        mark: true,
        isSubmitting: false,
        showDialog: false
      });
      notify(
        `${data.courses} courses and ${data.products} products successfully ${
          mark ? "marked" : "unmarked"
        } as sold out`,
        "success"
      );
    } catch (e) {
      notify(e.message, "error");
      setState({ ...state, isSubmitting: false });
    } finally {
      dispatch(fetchEnd());
    }
  }

  return (
    <>
      <Button onClick={onOpenDialog} label="Mark as Sold Out" />
      <Dialog fullWidth open={showDialog} onClose={onCloseDialog}>
        <DialogTitle>
          Mark All Courses & Products as Sold Out in Marketplace
        </DialogTitle>
        <DialogContent>
          <div>
            <Select style={{ width: "230px" }} value={mark} onChange={onChange}>
              <MenuItem value={true}>Mark as sold out </MenuItem>
              <MenuItem value={false}>Unmark as sold out </MenuItem>
            </Select>
          </div>
        </DialogContent>
        <DialogActions>
          <SaveButton
            label="Submit"
            saving={isSubmitting}
            disabled={isSubmitting}
            handleSubmitWithRedirect={onSubmit}
            icon={<></>}
          />
          <Button label="Cancel" onClick={onCloseDialog} />
        </DialogActions>
      </Dialog>
    </>
  );
}
