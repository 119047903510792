import React from "react";
import {
  CardActions,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  ExportButton,
  Filter,
  List,
  RefreshButton,
  ArrayInput,
  SimpleFormIterator,
  TextField,
  TextInput,
  FormDataConsumer,
  FormTab,
  TabbedForm,
  ShowController,
  SelectInput
} from "react-admin";
import { Button } from "@mui/material";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";

const BlockStructureActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);
const BlockStructureFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by name" source="q" alwaysOn />
  </Filter>
);

export const BlockStructureList = props => (
  <List
    {...props}
    filters={<BlockStructureFilter />}
    actions={<BlockStructureActions />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <TextField source="name" />
    </Datagrid>
  </List>
);

function StructureInput({ getSource, label = "Type", param = {}, inputProps }) {
  return (
    <>
      <SelectInput
        {...inputProps}
        style={{ marginRight: "12px" }}
        label={label}
        source={getSource("type")}
        choices={[
          { id: "string", name: "String" },
          { id: "number", name: "Number" },
          { id: "boolean", name: "Boolean" },
          { id: "object", name: "Object" },
          { id: "array", name: "Array" },
          { id: "enum", name: "Enum" }
        ]}
      />
      {param.type === "array" && (
        <StructureInput
          getSource={field => getSource(`structure.${field}`)}
          label="Of"
          param={param.structure}
          inputProps={inputProps}
        />
      )}
      {param.type === "enum" && (
        <ArrayInput source={getSource("structure")} label={false}>
          <SimpleFormIterator
            getItemLabel={() => ""}
            addButton={
              <Button label={"Add option"}>
                <AddIcon />
              </Button>
            }
            removeButton={
              <Button>
                <CloseIcon />
              </Button>
            }
          >
            <FormDataConsumer>
              {({
                formData,
                getSource,
                scopedFormData = {},
                ...inputProps
              }) => {
                return (
                  <>
                    <TextInput
                      {...inputProps}
                      style={{ marginRight: "12px" }}
                      source={getSource("id")}
                      label="Value"
                    />
                    <TextInput
                      {...inputProps}
                      source={getSource("name")}
                      label="Label"
                    />
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      )}
      {param.type === "object" && (
        <ArrayInput source={getSource("structure")} label={false}>
          <SimpleFormIterator
            getItemLabel={() => ""}
            addButton={
              <Button label={"Add property"}>
                <AddIcon />
              </Button>
            }
            removeButton={
              <Button>
                <CloseIcon />
              </Button>
            }
          >
            <FormDataConsumer>
              {({
                formData,
                getSource,
                scopedFormData = {},
                ...inputProps
              }) => {
                return (
                  <>
                    <TextInput
                      {...inputProps}
                      style={{ marginRight: "12px" }}
                      source={getSource("name")}
                      label="Name"
                    />
                    <StructureInput
                      param={scopedFormData}
                      getSource={getSource}
                      inputProps={inputProps}
                    />
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      )}
    </>
  );
}

export const BlockStructureCreate = props => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="General">
          <TextInput source="name" label="Name" />
        </FormTab>
        <FormTab label="Structure">
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <StructureInput
                  param={formData.structure}
                  getSource={source => `structure.${source}`}
                />
              );
            }}
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export function BlockStructureEdit(props) {
  return (
    <ShowController {...props}>
      {({ record = {} }) => {
        record.props = record.props ? record.props : [];
        return (
          <Edit {...props}>
            <TabbedForm>
              <FormTab label="General">
                <TextInput disabled label="Id" source="id" />
                <TextInput source="name" />
                <DateField
                  label="Created Date"
                  source="createdAt"
                  locales="en-GB"
                />
                <DateField
                  label="Updated Date"
                  source="updatedAt"
                  locales="en-GB"
                />
              </FormTab>
              <FormTab label="Structure">
                <FormDataConsumer>
                  {({ formData, ...inputProps }) => {
                    return (
                      <StructureInput
                        param={formData.structure}
                        getSource={source => `structure.${source}`}
                        inputProps={inputProps}
                      />
                    );
                  }}
                </FormDataConsumer>
              </FormTab>
            </TabbedForm>
          </Edit>
        );
      }}
    </ShowController>
  );
}
