import React from "react";
import {
    ArrayInput, AutocompleteArrayInput, BooleanField, BooleanInput, Create, Datagrid, Edit, FormTab,
    List, ReferenceArrayInput, required, SimpleForm, SimpleFormIterator, TabbedForm, TextField,
    TextInput
} from "react-admin";
import { ColorInput } from "react-admin-color-input";

export const ClassListList = props => (
  <List {...props} sort={{ field: "createdAt", order: "ASC" }}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Title" source="title" />
      <BooleanField label="Featured" source="isFeatured" />
    </Datagrid>
  </List>
);

export const ClassListCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" label="Title" validate={[required()]} />
      <TextInput
        multiline
        fullWidth
        source="description"
        label="Description"
        validate={[required()]}
      />
      <ColorInput
        source="textColor"
        label="Text Color"
        validate={[required()]}
      />
      <ColorInput
        source="backgroundColor"
        label="Background Color"
        validate={[required()]}
      />
      <TextInput
        source="coverPhoto"
        label="Cover Image Url"
        validate={[required()]}
        fullWidth
      />
      <TextInput
        source="thumbnail"
        label="Thumbnail Url"
        validate={[required()]}
        fullWidth
      />
      <TextInput
        source="navThumbnail"
        label="Navigation Thumbnail Url"
        fullWidth
      />
      <BooleanInput label="Featured" source="isFeatured" />
    </SimpleForm>
  </Create>
);

export const ClassListEdit = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="Summary">
        <TextInput source="title" label="Title" validate={[required()]} />
        <TextInput source="slug" label="Slug" />
        <TextInput
          multiline
          fullWidth
          source="description"
          label="Description"
          validate={[required()]}
        />
        <ColorInput
          source="textColor"
          label="Text Color"
          validate={[required()]}
        />
        <ColorInput
          source="backgroundColor"
          label="Background Color"
          validate={[required()]}
        />
        <TextInput
          source="coverPhoto"
          label="Cover Image Url"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          source="thumbnail"
          label="Thumbnail Url"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          source="navThumbnail"
          label="Navigation Thumbnail Url"
          fullWidth
        />
        <BooleanInput label="Featured" source="isFeatured" />
      </FormTab>
      <FormTab label="Sub lists">
        <ArrayInput source="subList" label="Sub Lists">
          <SimpleFormIterator>
            <TextInput source="title" label="Title" validate={[required()]} />
            <ReferenceArrayInput
              source="classes"
              reference="courses"
              label="Classes"
              perPage={8}
              filterToQuery={searchText => ({ qTitle: searchText })}
            >
              <AutocompleteArrayInput
                optionText="title"
                optionValue="_id"
                allowEmpty={true}
                shouldRenderSuggestions={val => {
                  return val.trim().length >= 2;
                }}
                highlightFirstSuggestion={false}
                options={{
                  fullWidth: true,
                  suggestionsContainerProps: {
                    disablePortal: false
                  }
                }}
              />
            </ReferenceArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);
