import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Papa from "papaparse";
import PropTypes from "prop-types";
import React from "react";
import { CREATE, fetchEnd, showNotification } from "react-admin";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import dataProvider from "../dataProvider";

class UploadSchoolsPayouts extends React.Component {
  constructor(props) {
    super(props);
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.state = {
      fileuploaded: false,
      items: []
    };
    this.handleUploadedFile = this.handleUploadedFile.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  handleUpload = () => {
    const { showNotification } = this.props;
    dataProvider(CREATE, "actions/make-manual-payouts-school", {
      data: {
        items: this.state.items
      }
    })
      .then(() => {
        showNotification("Marked the bookings and product as paid!");
      })
      .catch(error => {
        showNotification(error.message, "error");
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  handleUploadedFile = acceptedFiles => {
    const reader = new FileReader();
    reader.onload = () => {
      const { data, errors } = Papa.parse(reader.result, { delimiter: "," });
      if (errors.length > 1) {
        console.log("error ", errors);
        alert("There was an error, call a dev!");
      } else {
        console.log("data ", data);
        let items = [];
        if (data.length > 1) {
          data.shift();
          for (let row of data) {
            items.push({
              schoolID: row[0],
              amount: row[2],
              reference: row[1]
            });
          }
          this.setState({
            items: items,
            fileuploaded: true
          });
        }
      }
    };
    acceptedFiles.forEach(file => reader.readAsBinaryString(file));
  };

  render() {
    return (
      <Card>
        <CardHeader title="Save SchoolIds Manual Payouts" />
        <CardContent>
          <Dropzone onDrop={this.handleUploadedFile}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>
                    Upload the CSV file with School id on first column and
                    reference on the second column by clicking here and press
                    save button below
                  </p>
                </div>
              </section>
            )}
          </Dropzone>
          <hr />
          <div>{this.state.fileuploaded ? "File uploaded" : "No file yet"}</div>
          <hr />
          <Button
            color="primary"
            onClick={this.handleUpload}
            disabled={!this.state.fileuploaded}
          >
            Save schoolsIDs reference as payouts
          </Button>
        </CardContent>
      </Card>
    );
  }
}

UploadSchoolsPayouts.propTypes = {
  showNotification: PropTypes.func
};

export default connect(null, {
  showNotification
})(UploadSchoolsPayouts);
