import React from "react";
import {
    CardActions, Create, CreateButton, Datagrid, DateField, Edit, ExportButton, Filter, List, RefreshButton, SimpleForm, TextField, TextInput
} from "react-admin";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);
const GroupRequestFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by name" source="q" alwaysOn />
  </Filter>
);

export const GroupRequestList = props => (
  <List {...props} filters={<GroupRequestFilter />} actions={<PostActions />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="slug" />
      <TextField source="category" />
    </Datagrid>
  </List>
);

export const GroupRequestCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="category" />
    </SimpleForm>
  </Create>
);

export const GroupRequestEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="name" />
      <TextInput disabled source="slug" />
      <TextInput source="category" />
      <DateField label="Created Date" source="createdAt" locales="en-GB" />
      <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
    </SimpleForm>
  </Edit>
);
