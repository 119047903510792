import { Checkbox } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconCancel from "@material-ui/icons/Cancel";
import React, { Component, Fragment } from "react";
import {
    Button, CREATE, crudGetMatching, fetchEnd,
    fetchStart, SaveButton, showNotification
} from "react-admin";
import { connect } from "react-redux";
import { change, isSubmitting, submit } from "redux-form";
import dataProvider from "../dataProvider";


class ResendGifcardEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      showDialog: false,
      recipient: true,
      sender: true,
      school: true
    };
  }

  handleClick = () => {
    this.setState({
      showDialog: true
    });
  };

  handleCloseClick = () => {
    this.setState({
      showDialog: false
    });
  };

  handleSaveClick = () => {
    const { fetchStart, fetchEnd, showNotification } = this.props;
    fetchStart();
    let giftcardID = this.props.data.id;
    console.log("handle save Click ", giftcardID);
    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider(CREATE, "actions/giftcard/resendEmail", {
      data: {
        giftcardID,
        recipient: this.state.recipient ? "enabled" : "disabled",
        sender: this.state.sender ? "enabled" : "disabled",
        school: this.state.school ? "enabled" : "disabled"
      }
    })
      .then(({ data }) => {
        this.setState({ showDialog: false });
        //hacky way to reload the data
        //@TODO window.location.reload();
      })
      .catch(error => {
        showNotification(error.message, "error");
      })
      .finally(() => {
        fetchEnd();
      });
  };

  handleChange = event => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  render() {
    const { showDialog } = this.state;
    const { isSubmitting } = this.props;
    return (
      <Fragment>
        <Button
          onClick={this.handleClick}
          label="Resend Giftcard email"
        ></Button>
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick}>
          <DialogTitle>Resend Giftcard Email</DialogTitle>
          <DialogContent>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.recipient}
                  onChange={this.handleChange}
                  name="recipient"
                  color="primary"
                />
              }
              label="Recipient"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.sender}
                  onChange={this.handleChange}
                  name="sender"
                  color="primary"
                />
              }
              label="Sender"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.school}
                  onChange={this.handleChange}
                  name="school"
                  color="primary"
                />
              }
              label="School"
            />
          </DialogContent>
          <DialogActions>
            <SaveButton
              label="Resend"
              saving={isSubmitting}
              handleSubmitWithRedirect={this.handleSaveClick}
            />
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting("post-quick-create")(state)
});

const mapDispatchToProps = {
  change,
  crudGetMatching,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
};

export default connect(mapStateToProps, mapDispatchToProps)(ResendGifcardEmail);
