import React from "react";
import {
    CardActions, Create, CreateButton, Datagrid, DateField, Edit, ExportButton, Filter, List, RefreshButton, SimpleForm, TextField, TextInput
} from "react-admin";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);

const DateNotificationRequestsFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by name" source="q" />
    <TextInput label="Search by email" source="qEmail" alwaysOn />
  </Filter>
);

export const DateNotificationRequestsList = props => (
  <List
    {...props}
    filters={<DateNotificationRequestsFilter />}
    actions={<PostActions />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="courseId" />
      <TextField source="courseTitle" />
      <TextField source="email" />
      <TextField source="schoolName" />
    </Datagrid>
  </List>
);

export const DateNotificationRequestsCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="courseId" />
      <TextInput source="courseTitle" />
      <TextInput source="email" />
      <TextInput source="schoolName" />
    </SimpleForm>
  </Create>
);

export const DateNotificationRequestsEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput label="Id" source="id" disabled />
      <TextInput source="courseId" disabled />
      <TextInput source="courseTitle" disabled />
      <TextInput source="email" disabled />
      <TextInput source="schoolName" disabled />
      <DateField label="Created Date" source="createdAt" locales="en-GB" />
      <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
    </SimpleForm>
  </Edit>
);
