import decode from "jwt-decode";

export const auth = {
  getToken() {
    return localStorage.getItem("token");
  },

  getUser() {
    try {
      return JSON.parse(localStorage.getItem("user"));
    } catch (e) {
      return false;
    }
  },

  isLoggedIn() {
    const token = localStorage.getItem("token");
    if (!token) return false;
    try {
      const decodedToken = decode(token);
      return decodedToken.exp > Date.now() / 1000;
    } catch (e) {
      return false;
    }
  },

  login(token, user) {
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
  },

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }
};
