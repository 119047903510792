import React from "react";
import {
  ArrayInput,
  BooleanField,
  BooleanInput,
  CardActions,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  ExportButton,
  Filter,
  FormTab,
  List,
  NumberInput,
  RefreshButton,
  SimpleForm,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput
} from "react-admin";
import { PapertrailReferenceTable } from "../components/PapertrailReferenceTable";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);
const PartnerLocationFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by name" source="q" alwaysOn />
  </Filter>
);

export const PartnerLocationList = props => (
  <List
    {...props}
    filters={<PartnerLocationFilter />}
    actions={<PostActions />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <BooleanField source="archived" />
      <TextField source="schoolId" />
      <TextField source="name" />
      <TextField source="region" />
      <TextField source="area" />
      <TextField source="city" />
    </Datagrid>
  </List>
);

export const PartnerLocationCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="schoolId" />
      <TextInput source="name" />
      <TextInput source="region" />
      <TextInput source="area" />
      <TextInput source="city" />
      <TextInput source="line1" />
      <TextInput source="postcode" />
      <TextInput source="city" />
      <NumberInput label="Latitude" source="geolocation.lat" />
      <NumberInput label="Longitude" source="geolocation.lng" />
      <TextInput source="archived" />
    </SimpleForm>
  </Create>
);

export const PartnerLocationEdit = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="Info">
        <TextInput label="Id" source="id" disabled />
        <BooleanInput source="archived" />
        <TextInput source="schoolId" />
        <TextInput source="name" />
        <TextInput source="region" />
        <TextInput source="area" />
        <TextInput source="city" />
        <TextInput source="line1" />
        <TextInput source="postcode" />
        <TextInput source="city" />
        <NumberInput label="Latitude" source="geolocation.lat" />
        <NumberInput label="Longitude" source="geolocation.lng" />
        <DateField label="Created Date" source="createdAt" locales="en-GB" />
        <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
      </FormTab>
      <FormTab label="Tags">
        <ArrayInput source="tags">
          <SimpleFormIterator>
            <TextInput />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Papertrail">
        <PapertrailReferenceTable />
      </FormTab>
    </TabbedForm>
  </Edit>
);
