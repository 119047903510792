import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconCancel from "@material-ui/icons/Cancel";
import { DateTimePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import React, { Component, Fragment } from "react";
import {
    Button, CREATE, crudGetMatching, fetchEnd,
    fetchStart, SaveButton, showNotification
} from "react-admin";
import { connect } from "react-redux";
import { change, isSubmitting, submit } from "redux-form";
import dataProvider from "../dataProvider";


class CancelBookingButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      showDialog: false,
      startDateTime: new Date()
    };
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      typeof nextProps.data != "undefined" &&
      nextProps.data.blockBookingStartDate
    ) {
      return {
        //startDateTime: new Date(nextProps.data.blockBookingStartDate)
      };
    } else {
      return null;
    }
  }

  handleClick = () => {
    let startDateTime = this.state.startDateTime;
    if (!startDateTime) {
      if (
        typeof this.props.data != "undefined" &&
        typeof this.props.data.session != "undefined"
      ) {
        startDateTime = this.props.data.session.startDateTime;
      }
    }
    this.setState({
      showDialog: true,
      startDateTime: startDateTime
    });
  };

  handleCloseClick = () => {
    let startDateTime = this.state.startDateTime;
    if (
      typeof this.props.data != "undefined" &&
      typeof this.props.data.session != "undefined"
    ) {
      startDateTime = this.props.data.session.startDateTime;
    }
    // reset the start date time
    this.setState({
      showDialog: false,
      startDateTime: startDateTime
    });
  };

  handleDateChange = date => {
    this.setState({ startDateTime: date });
  };

  handleSaveClick = () => {
    const { fetchStart, fetchEnd, showNotification } = this.props;
    fetchStart();
    let bookingID = this.props.data.id;
    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider(CREATE, "actions/booking/setBlockBookingDate", {
      data: {
        bookingID,
        startDateTime: this.state.startDateTime
      }
    })
      .then(({ data }) => {
        this.setState({ showDialog: false });
        //hacky way to reload the data
        window.location.reload();
      })
      .catch(error => {
        showNotification(error.message, "error");
      })
      .finally(() => {
        fetchEnd();
      });
  };

  render() {
    const { showDialog } = this.state;
    const { isSubmitting } = this.props;
    return (
      <Fragment>
        <Button
          onClick={this.handleClick}
          label="Set BlockBooking Date"
        ></Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Create post"
        >
          <DialogTitle>Set BlockBooking date</DialogTitle>
          <DialogContent>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                value={this.state.startDateTime}
                label="Date"
                disable={true}
                onChange={this.handleDateChange}
              />
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions>
            <SaveButton
              label="Set date"
              saving={isSubmitting}
              handleSubmitWithRedirect={this.handleSaveClick}
            />
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting("post-quick-create")(state)
});

const mapDispatchToProps = {
  change,
  crudGetMatching,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelBookingButton);
