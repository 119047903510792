import React from "react";
import {
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
  Filter,
  FunctionField,
  SelectInput,
  DateField
} from "react-admin";
import { Link } from "../components/Link";

function PapertrailFilter(props) {
  return (
    <Filter {...props}>
      <SelectInput
        label="Action"
        source="qActionType"
        choices={[
          { id: "add_course_date", name: "Add Course Date" },
          { id: "add_ticket_type", name: "Add Ticket Type" },
          { id: "add_session_ticket_type", name: "Add Session Ticket Type" },
          { id: "assign_tutor", name: "Assign Tutor" },
          { id: "cancel_booking", name: "Cancel Booking" },
          { id: "cancel_session", name: "Cancel Session" },
          { id: "change_product_price", name: "Change Product Price" },
          { id: "change_product_stock", name: "Change Product Stock" },
          {
            id: "change_session_ticket_capacity",
            name: "Change Session Ticket Type Capacity"
          },
          { id: "change_ticket_type_price", name: "Change Ticket Type Price" },
          { id: "delete_course_date", name: "Delete Course Date" },
          { id: "delete_ticket_type", name: "Delete Ticket Type" },
          { id: "edit_booking_ticket", name: "Edit Booking Ticket" },
          { id: "refund_booking", name: "Refund Booking" },
          {
            id: "remove_session_ticket_type",
            name: "Remove Session Ticket Type"
          },
          { id: "reschedule_booking", name: "Reschedule Booking" }
        ]}
        alwaysOn
      />
      <SelectInput
        label="User Type"
        source="qUserType"
        choices={[
          { id: "guest", name: "Guest" },
          { id: "obby", name: "Obby Admin" },
          { id: "system", name: "System" },
          { id: "user", name: "User" }
        ]}
        alwaysOn
      />
      <SelectInput
        label="Item Type"
        source="qItemType"
        choices={[
          { id: "booking", name: "Booking" },
          { id: "course", name: "Course" },
          { id: "discount", name: "Discount" },
          { id: "location", name: "Location" },
          { id: "private_class", name: "Private Class" },
          { id: "product", name: "Product" },
          { id: "school", name: "School" },
          { id: "session", name: "Session" },
          { id: "ticket_type", name: "Ticket Type" },
          { id: "user", name: "User" }
        ]}
        alwaysOn
      />
      <TextInput label="Item Id" source="qItemId" />
      <TextInput label="User Email" source="qUserEmail" />
    </Filter>
  );
}
export function PapertrailList(props) {
  return (
    <List
      {...props}
      filters={<PapertrailFilter />}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <Datagrid rowClick="edit">
        <TextField source="actionType" label="Action" />
        <TextField source="actionText" label="" />
        <FunctionField
          label="Item"
          render={record => (
            <span>
              {record.itemType}{" "}
              <Link to={getItemUrl(record)}>#{record.itemId}</Link>
            </span>
          )}
        />
        <FunctionField
          label="User"
          render={record => {
            if (record.userType === "user" || record.userType === "obby")
              return (
                <>
                  <Link resource="users" id={record.userId}>
                    {record.userEmail}
                  </Link>
                  {record.userType === "obby" && " (Internal)"}
                </>
              );
            return <span>{record.userType}</span>;
          }}
        />
        <DateField
          label="Date"
          source="createdAt"
          locales="en-GB"
          sortable
          options={{
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "Europe/London"
          }}
        />
      </Datagrid>
    </List>
  );
}

export const PapertrailEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="actionType" label="Type" />
      <TextField source="actionText" label="action" multiline fullWidth />
      <TextField source="itemId" label="Item Id" />
      <TextField source="itemType" />
      <TextField source="userType" />
      <TextField source="userId" label="User Id" />
      <TextField source="userEmail" />
      <DateField
        label="Date"
        source="createdAt"
        locales="en-GB"
        options={{
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "Europe/London"
        }}
      />
    </SimpleForm>
  </Edit>
);

function getItemUrl(record) {
  switch (record.itemType) {
    case "booking":
      return `/bookings/${record.itemId}`;
    case "course":
      return `/courses/${record.itemId}`;
    case "discount":
      return `/discounts/${record.itemId}`;
    case "location":
      return `/partnerLocations/${record.itemId}`;
    case "private_class":
      return `/privateClasses/${record.itemId}`;
    case "product":
      return `/products/${record.itemId}`;
    case "product_purchases":
      return `/productPurchases/${record.itemId}`;
    case "session":
      return `/sessions/${record.itemId}`;
    case "ticket_type":
      return `/ticketTypes/${record.itemId}`;
    case "user":
      return `/users/${record.itemId}`;
    default:
      return;
  }
}
