import jsonServerProvider from "ra-data-json-server";
import { fetchUtils } from "react-admin";
import { auth } from "./config/auth";

let apiDomain = process.env.REACT_APP_API;
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = auth.getToken();
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};
const dataProvider = jsonServerProvider(`${apiDomain}/v1`, httpClient);

export default dataProvider;
