import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import IconCancel from "@material-ui/icons/Cancel";
import React, { Component, Fragment } from "react";
import {
  Button,
  CREATE,
  crudGetMatching,
  fetchEnd,
  fetchStart,
  GET_ONE,
  SaveButton,
  showNotification
} from "react-admin";
import { connect } from "react-redux";
import { change, isSubmitting, submit } from "redux-form";
import dataProvider from "../../dataProvider";
const CancelationReasonItems = [
  {
    label: "I booked this class by mistake",
    value: "I booked this class by mistake"
  },
  {
    label: "I can no longer attend this date (or any others)",
    value: "I can no longer attend this date (or any others)"
  },
  {
    label: "I found this class cheaper elsewhere",
    value: "I found this class cheaper elsewhere"
  },
  {
    label: "I found another class that suits me better",
    value: "I found another class that suits me better"
  },
  {
    label: "I want to bring a friend, but there is no space",
    value: "I want to bring a friend, but there is no space"
  },
  {
    label: "My class was cancelled by the Teacher",
    value: "My class was cancelled by the Teacher"
  },
  {
    label: "My class was cancelled by the Teacher",
    value: "Unable due to COVID-19"
  }
];
class RefundOrderItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      showDialog: false,
      startDateTime: new Date(),
      reason: "",
      reasonMessage: "",
      sendCustomerEmail: false,
      isPartialRefund: false,
      partialRefundAmount: 0,
      alsoCancelBooking: true
    };
    this.handleCustomerEmailOption = this.handleCustomerEmailOption.bind(this);
    this.handleIsPartialRefund = this.handleIsPartialRefund.bind(this);
    this.handlePartialRefundAmount = this.handlePartialRefundAmount.bind(this);
  }

  handleCustomerEmailOption(e) {
    this.setState({
      sendCustomerEmail: e.target.checked
    });
  }
  handleIsPartialRefund(e) {
    this.setState({
      isPartialRefund: e.target.checked
    });
  }
  handlePartialRefundAmount(e) {
    this.setState({
      partialRefundAmount: e.target.value
    });
  }
  handleAlsoCancelBooking(e) {
    this.setState({
      alsoCancelBooking: e.target.checked
    });
  }

  handleClick = () => {
    console.log("this props data ", this.props.data);
    let startDateTime = this.state.startDateTime;
    if (!startDateTime) {
      if (
        typeof this.props.data != "undefined" &&
        typeof this.props.data.session != "undefined"
      ) {
        startDateTime = this.props.data.session.startDateTime;
      }
    }
    let reason = "";
    let reasonMessage = "";
    if (this.props.data.refunded) {
      if (
        this.props.data.refundedDetails &&
        this.props.data.refundedDetails.reason
      ) {
        reason = this.props.data.refundedDetails.reason;
      }
      if (
        this.props.data.refundedDetails &&
        this.props.data.refundedDetails.message
      ) {
        reasonMessage = this.props.data.refundedDetails.message;
      }
    } else {
      if (this.props.data.rejectReason) {
        reasonMessage = this.props.data.rejectReason;
      }
    }

    this.setState({
      showDialog: true,
      startDateTime,
      reasonMessage,
      reason
    });
  };

  handleCloseClick = () => {
    let startDateTime = this.state.startDateTime;
    if (
      typeof this.props.data != "undefined" &&
      typeof this.props.data.session != "undefined"
    ) {
      startDateTime = this.props.data.session.startDateTime;
    }
    // reset the start date time
    this.setState({
      showDialog: false,
      startDateTime: startDateTime
    });
  };

  handleDateChange = date => {
    this.setState({ startDateTime: date });
  };

  handleSaveClick = () => {
    const { fetchStart, fetchEnd, showNotification } = this.props;
    fetchStart();
    let orderID = this.props.data.id;
    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider(CREATE, "actions/order/adminRefundOrderItem", {
      data: {
        orderItemID: orderID,
        reason: this.state.reason,
        reasonMessage: this.state.reasonMessage,
        sendCustomerEmail: this.state.sendCustomerEmail,
        isPartialRefund: this.state.isPartialRefund,
        partialRefundAmount: this.state.partialRefundAmount,
        alsoCancelBooking: this.state.alsoCancelBooking
      }
    })
      .then(({ data }) => {
        this.setState({ showDialog: false });
        //hacky way to reload the data
        window.location.reload();
      })
      .catch(error => {
        showNotification(error.message, "error");
      })
      .finally(() => {
        fetchEnd();
      });
  };

  handleReasonChange = e => {
    console.log("reason changed for ", e);
    this.setState({
      reason: e.target.value
    });
  };

  handleReasonMessageChange = e => {
    this.setState({
      reasonMessage: e.target.value
    });
  };

  render() {
    const { showDialog } = this.state;
    const { isSubmitting } = this.props;
    return (
      <Fragment>
        <Button onClick={this.handleClick} label="Refund Order Item" />
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick}>
          <DialogTitle>Refund Order Item</DialogTitle>
          <DialogContent>
            <div>
              <p>Refund reason</p>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.reason}
                onChange={this.handleReasonChange.bind(this)}
              >
                {CancelationReasonItems.map((reason, index) => {
                  return (
                    <MenuItem key={reason.value} value={reason.value}>
                      {reason.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div>
              <p>Refund messge</p>
              <TextField
                value={this.state.reasonMessage}
                multiline={true}
                fullWidth={true}
                onChange={this.handleReasonMessageChange.bind(this)}
              />
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.sendCustomerEmail}
                  onChange={this.handleCustomerEmailOption}
                  name="school"
                  color="primary"
                />
              }
              label="Send customer email?"
            />
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.alsoCancelBooking}
                    onChange={this.handleAlsoCancelBooking.bind(this)}
                    name="school"
                    color="primary"
                  />
                }
                label="Also Cancel the order?"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isPartialRefund}
                    onChange={this.handleIsPartialRefund}
                    name="school"
                    color="primary"
                  />
                }
                label="Is Partial Refund?"
              />
              {this.state.isPartialRefund && (
                <TextField
                  label="Partial Amount"
                  type="number"
                  value={this.state.partialRefundAmount}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={this.handlePartialRefundAmount}
                />
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <SaveButton
              label="Refund order item"
              saving={isSubmitting}
              handleSubmitWithRedirect={this.handleSaveClick}
            />
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting("post-quick-create")(state)
});

const mapDispatchToProps = {
  change,
  crudGetMatching,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
};

export default connect(mapStateToProps, mapDispatchToProps)(RefundOrderItem);
