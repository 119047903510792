import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import PropTypes from "prop-types";
import React from "react";
import { CREATE, fetchEnd, showNotification } from "react-admin";
import { connect } from "react-redux";
import dataProvider from "./../dataProvider";

class ResetDatabaseToSnapShot extends React.Component {
  handleClick = () => {
    const { showNotification } = this.props;
    dataProvider(CREATE, "actions/reset-database-to-snap", {})
      .then(() => {
        showNotification(
          "Database will be reset. This process may take some few minutes (up to 30 minutes) please wait."
        );
      })
      .catch(error => {
        showNotification(error.message, "error");
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  render() {
    return (
      <Card>
        <CardHeader title="Reset Database To SnapShot" />
        <CardContent>
          <p>
            Click on the button to reset the database to a specific SnapShot
          </p>
          <Button color="primary" onClick={this.handleClick}>
            Reset database to snapshot
          </Button>
        </CardContent>
      </Card>
    );
  }
}

ResetDatabaseToSnapShot.propTypes = {
  showNotification: PropTypes.func
};

export default connect(null, {
  showNotification
})(ResetDatabaseToSnapShot);
