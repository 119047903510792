export const DashboardPermissions = {
  CREATE_SECOND_TUTOR: "CREATE_SECOND_TUTOR",
  CREATE_LIMITED_TUTORS: "CREATE_LIMITED_TUTORS",
  USE_BULK_CHANGES: "USE_BULK_CHANGES",
  MANAGE_BUNDLE_BOOKINGS: "MANAGE_BUNDLE_BOOKINGS",
  USE_CALENDAR_INTEGRATION: "USE_CALENDAR_INTEGRATION",
  AVAILABILITY_VIEW_APPOINTMENTS: "AVAILABILITY_VIEW_APPOINTMENTS",
  CANCEL_BOOKING: "CANCEL_BOOKING",
  VIEW_CONTACT_INFO: "VIEW_CONTACT_INFO",
  COURSE_NOTIFICATIONS: "COURSE_NOTIFICATIONS",
  CUSTOM_CANCELLATION_POLICY: "CUSTOM_CANCELLATION_POLICY",
  MANAGE_CUSTOMERS: "MANAGE_CUSTOMERS",
  USE_DISCOUNTS: "USE_DISCOUNTS",
  USE_SPECIFIC_DISCOUNTS: "USE_SPECIFIC_DISCOUNTS",
  USE_EARLY_ACCESS: "USE_EARLY_ACCESS",
  EDIT_BOOKING_GUEST: "EDIT_BOOKING_GUEST",
  EMAIL_BOOKING: "EMAIL_BOOKING",
  USE_EXTERNAL_BOOKINGS: "USE_EXTERNAL_BOOKINGS",
  DOWNLOAD_CUSTOMERS: "DOWNLOAD_CUSTOMERS",
  FILTER_CUSTOMERS: "FILTER_CUSTOMERS",
  CREATE_STANDARD_LIMITED_POSTAGES: "CREATE_STANDARD_LIMITED_POSTAGES",
  MANAGE_INCOME: "MANAGE_INCOME",
  DOWNLOAD_INCOME: "DOWNLOAD_INCOME",
  CREATE_UNLIMITED_POSTAGES: "CREATE_UNLIMITED_POSTAGES",
  WIDGET_HIDE_EMBED_BRANDING: "WIDGET_EMBED_BRANDING",
  MANAGE_PRIVATE_PAYMENTS: "MANAGE_PRIVATE_PAYMENTS",
  PRIVATE_TAG_CREATION: "PRIVATE_TAG_CREATION",
  USE_RECURRING_CLASS_DATES: "USE_RECURRING_CLASS_DATES",
  REMOVE_REVIEWS: "REMOVE_REVIEWS",
  RESCHEDULE_BOOKING: "RESCHEDULE_BOOKING",
  RESEND_EMAILS: "RESEND_EMAILS",
  MANAGE_REVIEWS: "MANAGE_REVIEWS",
  SHARE_CLASS: "SHARE_CLASS",
  MANAGE_TICKET_TYPES: "MANAGE_TICKET_TYPES",
  TIMETABLE_BULK_ACTIONS: "TIMETABLE_BULK_ACTIONS",
  TIMETABLE_FILTERS: "TIMETABLE_FILTER",
  CREATE_UNLIMITED_TUTORS: "CREATE_UNLIMITED_TUTORS",
  MANAGE_WAITING_LIST: "MANAGE_WAITING_LIST",
  CUSTOMIZE_WIDGET: "CUSTOMIZE_WIDGET",
  USE_WIDGET_GIFT_CARDS: "USE_WIDGET_GIFT_CARDS",
  USE_WIDGET: "USE_WIDGET",
  USE_ZOOM_INTEGRATION: "USE_ZOOM_INTEGRATION",
  LARGE_GROUP_ENQUIRE: "LARGE_GROUP_ENQUIRE",
  ARCHIVE_SCHOOL: "ARCHIVE_SCHOOL"
};
