import React from "react";
import { AppBar, UserMenu, MenuItemLink } from "react-admin";
import SettingsIcon from "@material-ui/icons/Person";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { auth } from "./config/auth";

const useStyles = makeStyles({
  avatar: {
    height: 24,
    width: 24
  }
});

export function AdminAppBar(props) {
  return <AppBar {...props} userMenu={<MyUserMenu />} />;
}

function MyUserMenu(props) {
  const user = auth.getUser();
  const classes = useStyles();

  if (!user) return <UserMenu {...props} />;

  return (
    <UserMenu
      {...props}
      label={user.name}
      icon={
        user.avatar ? (
          <Avatar className={classes.avatar} src={user.avatar} />
        ) : (
          undefined
        )
      }
    >
      <MenuItemLink
        to={`/users/${user.id}`}
        primaryText="My Account"
        leftIcon={<SettingsIcon />}
      />
    </UserMenu>
  );
}
