import React, { useEffect, useState } from "react";
import {
  ArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  FormTab,
  List,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  NumberInput
} from "react-admin";
import { axiosInstance } from "../config/axios";
import MarkdownInput from "ra-input-markdown";

export const LearnContentPageList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="landingPageSlug" />
    </Datagrid>
  </List>
);

export const LearnContentPageCreate = props => {
  let [choices, setChoices] = useState([]);
  useEffect(() => {
    console.log("inside the edit");
    async function fetchData() {
      const response = await axiosInstance.get(
        `/actions/get-tags-for-learn-cms`
      );
      setChoices(response.data);
    }
    fetchData();
  }, []);

  return <Create {...props}>{createOrEditForm(choices)}</Create>;
};

const createOrEditForm = choices => (
  <TabbedForm>
    <FormTab label="Setup">
      <TextInput
        fullWidth
        label="What is the slug of this page? *please include the /learn as prefix"
        source="landingPageSlug"
      />
      <BooleanInput
        label="Is this page enabled?"
        source="hasLandingPageEnabled"
      />
      <BooleanInput
        label="Do you want to prevent Google from indexing?"
        source="hideFromGoogle"
      />
      <AutocompleteArrayInput
        source="searchResultsBlock"
        choices={choices}
        suggestionLimit={10}
        allowEmpty={true}
      />
      <NumberInput
        source="searchResultsBlockNumberOfItems"
        label="Number of items"
        initialValue={22}
      />
      <DateField label="Created Date" source="createdAt" locales="en-GB" />
      <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
    </FormTab>
    <FormTab label="General">
      <TextInput fullWidth label="seoTitle" source="generalBlock.seoTitle" />
      <TextInput
        multiline
        fullWidth
        label="SEO Description"
        source="generalBlock.seoDescription"
      />
      <TextInput fullWidth source="generalBlock.h1Title" />
      <MarkdownInput source="generalBlock.h1Teaser" />
      <MarkdownInput source="generalBlock.h1Description" />
      <TextInput
        fullWidth
        label="H1 Image URL"
        source="generalBlock.h1ImageURL"
      />
    </FormTab>
    <FormTab label="Related block">
      <BooleanInput source="relatedBlock.enabled" />
      <TextInput source="relatedBlock.title" />
      <ArrayInput source="relatedBlock.content">
        <SimpleFormIterator>
          <TextInput source="title" label="Title" />
          <TextInput source="description" label="Description" />
          <TextInput source="destinationURL" label="Destination URL" />
          <TextInput source="imageURL" label="Image URL " />
        </SimpleFormIterator>
      </ArrayInput>
    </FormTab>
    <FormTab label="Explanation Block">
      <BooleanInput source="explanationBlock.enabled" />
      <TextInput source="explanationBlock.title" />
      <ArrayInput source="explanationBlock.content">
        <SimpleFormIterator>
          <TextInput fullWidth source="question" label="question" />
          <MarkdownInput source="answer" label="answer" />
        </SimpleFormIterator>
      </ArrayInput>
    </FormTab>
    <FormTab label="Blog Posts Block">
      <BooleanInput source="blogPostsBlock.enabled" />
      <TextInput source="blogPostsBlock.title" />
      <ArrayInput source="blogPostsBlock.content">
        <SimpleFormIterator>
          <TextInput source="title" label="Title" />
          <TextInput source="description" label="Description" />
          <TextInput source="destinationURL" label="Destination URL" />
          <TextInput source="imageURL" label="Image URL " />
        </SimpleFormIterator>
      </ArrayInput>
    </FormTab>
    <FormTab label="FAQ Block">
      <BooleanInput source="faqBlock.enabled" />
      <TextInput source="faqBlock.title" />
      <ArrayInput source="faqBlock.content">
        <SimpleFormIterator>
          <TextInput fullWidth source="question" label="question" />
          <TextInput multiline fullWidth source="answer" label="answer" />
        </SimpleFormIterator>
      </ArrayInput>
    </FormTab>
  </TabbedForm>
);
export const LearnContentPageEdit = props => {
  let [choices, setChoices] = useState([]);
  useEffect(() => {
    console.log("inside the edit");
    async function fetchData() {
      const response = await axiosInstance.get(
        `/actions/get-tags-for-learn-cms`
      );
      setChoices(response.data);
    }
    fetchData();
  }, []);

  return <Edit {...props}>{createOrEditForm(choices)}</Edit>;
};
