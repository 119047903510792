import React from "react";
import { CardActions } from "react-admin";
import CancelOrder from "./CancelOrder";
import RefundOrder from "./RefundOrder";

export default function OrderEditActions(props) {
  const { data: order } = props;
  console.log("props ", props);
  return <></>;
  if (!order) return <></>;

  return (
    <CardActions>
      <CancelOrder data={order} />
      <RefundOrder data={order} />
    </CardActions>
  );
}
