import React from "react";
import {
  BooleanInput,
  CardActions,
  Datagrid,
  DateField,
  Edit,
  ExportButton,
  Filter,
  FormTab,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  RefreshButton,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  DateInput
} from "react-admin";
import { PapertrailReferenceTable } from "../components/PapertrailReferenceTable";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);

const TickeTypeFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by name" source="q" alwaysOn />
  </Filter>
);

export const TicketTypeList = props => (
  <List {...props} filters={<TickeTypeFilter />} actions={<PostActions />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <NumberField source="price" />
      <ReferenceField label="Course" source="courseId" reference="courses">
        <TextField source="title" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const TicketTypeEdit = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="Summary">
        <TextInput label="Id" source="id" disabled />
        <TextInput label="name" source="name" />
        <TextInput source="description" />
        <NumberInput source="price" />
        <ReferenceField label="Course" source="courseId" reference="courses">
          <TextField source="title" />
        </ReferenceField>
        <NumberInput source="numberOfGuestsPerTicket" />
        <NumberInput source="minTicketsPerOrder" />
        <SelectInput
          label="Visibility"
          source="visibility"
          choices={[
            { id: "public", name: "public" },
            { id: "hidden", name: "hidden" }
          ]}
        />
        <SelectInput
          label="BookingType"
          source="bookingType"
          choices={[
            { id: "regular", name: "regular" },
            { id: "flexitime", name: "flexitime" },
            { id: "bundle", name: "bundle" }
          ]}
        />
        <SelectInput
          label="status"
          source="status"
          choices={[
            { id: "enabled", name: "enabled" },
            { id: "disabled", name: "disabled" },
            { id: "deleted", name: "deleted" }
          ]}
        />
        <DateField label="Created Date" source="createdAt" locales="en-GB" />
        <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
      </FormTab>
      <FormTab label="Price List">
        <ArrayInput source="pricesAvailable">
          <SimpleFormIterator>
            <NumberInput source="price" />
            <BooleanInput source="isDefault" />
            <DateInput source="fromDate" locales="en-GB" />
            <TextInput source="description" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Time Constraints">
        <BooleanInput source="hasTimeConstraint" />
        <NumberInput source="timeConstraintDaysBefore" />
        <NumberInput source="timeConstraintHoursBefore" />
      </FormTab>
      <FormTab label="VAT constraints">
        <BooleanInput source="isVatIncluded" />
        <NumberInput source="vat" />
      </FormTab>

      <FormTab label="Papertrail">
        <PapertrailReferenceTable />
      </FormTab>
    </TabbedForm>
  </Edit>
);
