import React from "react";
import {
  CardActions,
  Datagrid,
  DateField,
  Edit,
  ExportButton,
  Filter,
  FormTab,
  List,
  RefreshButton,
  ShowController,
  TabbedForm,
  TextField,
  TextInput,
  NumberField,
  ArrayField,
  SelectInput,
  DateInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator
} from "react-admin";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);
const PhysicalGiftcardFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by ID" source="qID" />
    <TextInput label="Search by code" source="qCode" />
    <SelectInput
      label="Search by Activation Status Type"
      source="qActivationStatusType"
      choices={[
        { id: "enabled", name: "enabled" },
        { id: "pending", name: "pending" },
        { id: "disabled", name: "disabled" },
        { id: "void", name: "void" },
        { id: "redemeed", name: "redemeed" }
      ]}
    />
    <DateInput
      label="Search by Activation Date"
      source="qActivationDate"
      locales="en-GB"
    />
    <TextInput label="Search by Recipient email" source="qEmail" />
    <TextInput label="Search by Buyer email" source="qBuyerEmail" />
    <TextInput label="Search by Discount ID" source="qDiscountID" />
    <TextInput
      label="Search by Stripe Payment ID"
      source="qStripePaymentIntentID"
      alwaysOn
    />
  </Filter>
);

export const PhysicalGiftcardList = props => (
  <List
    {...props}
    filters={<PhysicalGiftcardFilter />}
    sort={{ field: "activationDate", order: "DESC" }}
    actions={<PostActions />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField label="Activation Status" source="activationStatus" />
      <TextField source="code" />
      <TextField source="amount" />
      <DateField
        label="Activation Date"
        source="activationDate"
        locales="en-GB"
      />
    </Datagrid>
  </List>
);

export const PhysicalGiftcardEdit = props => (
  <ShowController {...props}>
    {({ record = {}, ...controllerProps }) => {
      console.info(record);
      return (
        <Edit {...props} record={record} {...controllerProps}>
          <TabbedForm>
            <FormTab label="General">
              <TextInput disabled label="Id" source="id" />
              <TextField source="serialNumber" />
              <TextField source="buyerName" />
              <NumberInput source="amount" />
              <NumberInput source="usedAmount" />
              <TextField source="activationStatus" />
              <DateField source="activationDate" locales="en-GB" />
              <TextField source="productID" />
              <DateField
                label="Created Date"
                source="createdAt"
                locales="en-GB"
              />
              <DateField
                label="Updated Date"
                source="updatedAt"
                locales="en-GB"
              />
            </FormTab>
            <FormTab label="Code">
              <TextField source="code" />
              <TextField source="prefixCode" />
              <TextField source="randomCode" />
              <TextField source="pinCode" />
            </FormTab>
            <FormTab label="usedHistory">
              <ArrayInput source="usedHistory">
                <SimpleFormIterator>
                  <DateInput source="date" locales="en-GB" />
                  <NumberInput source="amountUsed" />
                  <TextInput source="emailUsed" />
                  <TextInput source="note" />
                </SimpleFormIterator>
              </ArrayInput>
            </FormTab>
            <FormTab label="BHN data">
              <TextField source="bhnData" />
              <TextField source="bhnVoidData" />
            </FormTab>
          </TabbedForm>
        </Edit>
      );
    }}
  </ShowController>
);
