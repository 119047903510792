import React, { Component } from "react";
import { CardActions } from "react-admin";
import ResendGifcardEmail from "./resendGifcardEmail";
import RefundGiftcard from "./RefundGiftcard";

class giftcardEditActions extends Component {
  render() {
    return (
      <CardActions>
        <ResendGifcardEmail data={this.props.data} />
        <RefundGiftcard data={this.props.data} />
      </CardActions>
    );
  }
}
export default giftcardEditActions;
