import { DashboardPermissions } from "./DashboardPermissions";

export const DashboardPermissionsLabels = {
  [DashboardPermissions.CREATE_SECOND_TUTOR]: "2 tutors",
  [DashboardPermissions.CREATE_LIMITED_TUTORS]: "5 tutors",
  [DashboardPermissions.USE_BULK_CHANGES]: "Bulk Changes",
  [DashboardPermissions.MANAGE_BUNDLE_BOOKINGS]: "Bundle Bookings",
  [DashboardPermissions.USE_CALENDAR_INTEGRATION]: "Calendar Integration",
  [DashboardPermissions.AVAILABILITY_VIEW_APPOINTMENTS]:
    "Can use Appointments on availability",
  [DashboardPermissions.CANCEL_BOOKING]: "Cancel Booking",
  [DashboardPermissions.VIEW_CONTACT_INFO]: "Contact Info",
  [DashboardPermissions.COURSE_NOTIFICATIONS]: "Course Notifications",
  [DashboardPermissions.CUSTOM_CANCELLATION_POLICY]:
    "Custom Cancellation Policy",
  [DashboardPermissions.MANAGE_CUSTOMERS]: "Customers",
  [DashboardPermissions.USE_DISCOUNTS]: "Discount Basic",
  [DashboardPermissions.USE_SPECIFIC_DISCOUNTS]: "Discount Full",
  [DashboardPermissions.USE_EARLY_ACCESS]: "Early Access",
  [DashboardPermissions.EDIT_BOOKING_GUEST]: "Edit Booking Guest",
  [DashboardPermissions.EMAIL_BOOKING]: "Email Booking",
  [DashboardPermissions.USE_EXTERNAL_BOOKINGS]: "External Booking",
  [DashboardPermissions.DOWNLOAD_CUSTOMERS]: "Extract customers data",
  [DashboardPermissions.FILTER_CUSTOMERS]: "Filter Customers",
  [DashboardPermissions.CREATE_STANDARD_LIMITED_POSTAGES]:
    "Five Postages Limitation",
  [DashboardPermissions.MANAGE_INCOME]: "Income Basic",
  [DashboardPermissions.DOWNLOAD_INCOME]: "Income Full",
  [DashboardPermissions.CREATE_UNLIMITED_POSTAGES]: "No Postages Limitation",
  [DashboardPermissions.WIDGET_HIDE_EMBED_BRANDING]: "Powered-by Branding",
  [DashboardPermissions.MANAGE_PRIVATE_PAYMENTS]: "Private Payments",
  [DashboardPermissions.PRIVATE_TAG_CREATION]: "Private tag creation",
  [DashboardPermissions.USE_RECURRING_CLASS_DATES]: "Recurring Class Dates",
  [DashboardPermissions.REMOVE_REVIEWS]: "Remove Reviews",
  [DashboardPermissions.RESCHEDULE_BOOKING]: "Reschedule Booking",
  [DashboardPermissions.RESEND_EMAILS]: "Resend Auto-Emails",
  [DashboardPermissions.MANAGE_REVIEWS]: "Reviews Settings",
  [DashboardPermissions.SHARE_CLASS]: "Share Class",
  [DashboardPermissions.MANAGE_TICKET_TYPES]: "Ticket Types",
  [DashboardPermissions.TIMETABLE_BULK_ACTIONS]: "Timetable Bulk Actions",
  [DashboardPermissions.TIMETABLE_FILTERS]: "Timetable Filters",
  [DashboardPermissions.CREATE_UNLIMITED_TUTORS]: "Unlimited tutors",
  [DashboardPermissions.MANAGE_WAITING_LIST]: "Waiting List",
  [DashboardPermissions.CUSTOMIZE_WIDGET]: "Widget Custom",
  [DashboardPermissions.USE_WIDGET_GIFT_CARDS]: "Widget Gift Cards",
  [DashboardPermissions.USE_WIDGET]: "Widget Lite",
  [DashboardPermissions.USE_ZOOM_INTEGRATION]: "Zoom Integration",
  [DashboardPermissions.LARGE_GROUP_ENQUIRE]: "Large Group Enquire",
  [DashboardPermissions.ARCHIVE_SCHOOL]: "Archive School"
};
