import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import PropTypes from "prop-types";
import React from "react";
import { showNotification } from "react-admin";
import { connect } from "react-redux";
import { axiosInstance } from "../config/axios";

class ExtractGiftcards extends React.Component {
  constructor(props) {
    super(props);
    this.getUsedWidgetGiftcardsNotPaid = this.getUsedWidgetGiftcardsNotPaid.bind(
      this
    );
    this.getExpiredWidgetGiftcards = this.getExpiredWidgetGiftcards.bind(this);
  }

  getUsedWidgetGiftcardsNotPaid = async () => {
    const response = await axiosInstance.get(
      "actions/generateWidgetGiftcardsReports"
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    const fileName = `UsedWidgetGiftcardsNotPaid-${+new Date()}.csv`; // whatever your file name .
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  getExpiredWidgetGiftcards = async () => {
    const response = await axiosInstance.get(
      "actions/generateExpiredWidgetGiftcardsWithoutUseReport"
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    const fileName = `ExpiredWidgetGiftcardsWithoutUse-${+new Date()}.csv`; // whatever your file name .
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  handleExtract = async () => {
    const { showNotification } = this.props;
    try {
      console.log("handle extract");
      await this.getUsedWidgetGiftcardsNotPaid();
      await this.getExpiredWidgetGiftcards();
      showNotification("Extract were generated and it will start the download");
    } catch (error) {
      showNotification(error.message, "error");
    }
  };

  render() {
    return (
      <Card>
        <CardHeader title="Extract Widget Giftcards reports" />
        <CardContent>
          <Button color="primary" onClick={this.handleExtract}>
            Get reports
          </Button>
        </CardContent>
      </Card>
    );
  }
}

ExtractGiftcards.propTypes = {
  showNotification: PropTypes.func
};

export default connect(null, {
  showNotification
})(ExtractGiftcards);
