import React from "react";
import { CardActions } from "react-admin";
import RefundProductPurchase from "./RefundProduct";
import PayProduct from "./PayProduct";
import { ResendProductPurchaseEmailsButton } from "./ResendProductPurchaseEmailsButton";

export default function productPurchasesEditActions(props) {
  const { data: purchase } = props;

  if (!purchase) return <></>;
  return (
    <CardActions>
      <RefundProductPurchase data={purchase} />
      <PayProduct data={purchase} />
      <ResendProductPurchaseEmailsButton purchase={purchase} />
    </CardActions>
  );
}
