import React from "react";
import {
  Datagrid,
  Edit,
  FormTab,
  FunctionField,
  List,
  ReferenceField,
  TabbedForm,
  TextField,
  TextInput
} from "react-admin";
import MarkdownInput from "ra-input-markdown";

export function ServicesList(props) {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField label="ID" source="_id" />
        <TextField label="Name" source="name" />
        <ReferenceField
          label="Private Payment"
          source="order"
          reference="privatePayments"
        >
          <FunctionField render={render => render._id} />
        </ReferenceField>
        <ReferenceField label="School" source="schoolId" reference="schools">
          <FunctionField render={render => render.name} />
        </ReferenceField>
      </Datagrid>
    </List>
  );
}

export function ServicesEdit(props) {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="Details">
          <TextField label="ID" source="_id" disabled />
          <ReferenceField label="School" source="schoolId" reference="schools">
            <FunctionField render={render => render.name} />
          </ReferenceField>
          <TextInput label="Name" source="name" required />
          <MarkdownInput label="Description" source="description" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}
