import React, { Component } from "react";
import {
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  Filter,
  FormTab,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  CardActions
} from "react-admin";
import BulkTag from "../actions/BulkTag";

const TagFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by name" source="q" />
    <SelectInput
      label="Search by Status"
      source="qStatus"
      choices={[
        { id: "enabled", name: "enabled" },
        { id: "waitingforapproval", name: "waitingforapproval" },
        { id: "disabled", name: "disabled" }
      ]}
    />
    <SelectInput
      label="Created by"
      source="qCreatedBy"
      choices={[
        { id: "obby", name: "obby" },
        { id: "teacher", name: "teacher" }
      ]}
      alwaysOn
    />
    <BooleanInput
      source="qHasLandingPageEnabled"
      label="Has landing page enabled?"
    />
  </Filter>
);

export const TagList = props => (
  <List {...props} filters={<TagFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField label="School Link" source="school" reference="schools">
        <TextField source="name" />
      </ReferenceField>
      <NumberField
        source="numberOfContentAssociatedToThisTag"
        label="Content Associated"
      />
      <DateField label="Created Date" source="createdAt" locales="en-GB" />
    </Datagrid>
  </List>
);

export const TagCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

class TagEditActions extends Component {
  render() {
    return (
      <CardActions>
        <BulkTag data={this.props.data} />
      </CardActions>
    );
  }
}

export const TagEdit = props => (
  <Edit {...props} actions={<TagEditActions />}>
    <TabbedForm>
      <FormTab label="General info">
        <TextInput label="Id" source="id" disabled />
        <TextInput source="name" />
        <TextInput source="slug" disabled />
        <SelectInput
          source="status"
          choices={[
            { id: "enabled", name: "enabled" },
            { id: "waitingforapproval", name: "waitingforapproval" },
            { id: "disabled", name: "disabled" }
          ]}
        />

        <SelectInput
          source="createdBy"
          choices={[
            { id: "obby", name: "obby" },
            { id: "teacher", name: "teacher" }
          ]}
        />

        <ReferenceField label="School Link" source="school" reference="schools">
          <TextField source="name" />
        </ReferenceField>
        <DateField label="Created Date" source="createdAt" locales="en-GB" />
        <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
      </FormTab>
      <FormTab label="Landing page content">
        <BooleanInput
          source="hasLandingPageEnabled"
          label="Want to show a landing page for this tag?"
        />
        <BooleanInput
          source="hideFromSearch"
          label="Hide from the keyword search and related"
        />
        <BooleanInput source="isFeatured" label="Show in navigation bar" />

        <TextInput
          multiline
          fullWidth
          source="landingPageContent.seoTitle"
          label="SEO title"
        />
        <TextInput
          multiline
          fullWidth
          source="landingPageContent.seoDescription"
          label="SEO Description"
        />
        <TextInput
          multiline
          fullWidth
          source="landingPageContent.h1Title"
          label="H1 Title"
        />
        <TextInput
          multiline
          fullWidth
          source="landingPageContent.h1Description"
          label="H1 Description"
        />
        <TextInput
          fullWidth
          source="landingPageContent.h1ImageURL"
          label="Image url"
        />
      </FormTab>
      <FormTab label="Stats Helpers Fields">
        <NumberInput
          source="numberOfContentAssociatedToThisTag"
          label="Content associated to this tag"
        />
        <NumberInput source="stats.inPerson" label="How many inperson" />
        <NumberInput source="stats.online" label="How many online" />
        <NumberInput source="stats.products" label="How many products" />
        <NumberInput
          source="stats.learningAtHome"
          label="How many learningAtHome"
        />
      </FormTab>
    </TabbedForm>
  </Edit>
);
