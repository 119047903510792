import React from "react";
import { getResources, MenuItemLink, Responsive } from "react-admin";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const AdminMenu = ({ resources, onMenuClick, logout }) => (
  <div>
    <MenuItemLink to="/" primaryText="Dashboard" />
    {resources.map(resource => (
      <MenuItemLink
        key={resource.name}
        to={`/${resource.name}`}
        primaryText={resource.name}
        onClick={onMenuClick}
      />
    ))}
    <Responsive
      small={logout}
      medium={null} // Pass null to render nothing on larger devices
    />
  </div>
);

const mapStateToProps = state => ({
  resources: getResources(state)
});

export default withRouter(connect(mapStateToProps)(AdminMenu));
