import { RunningDays } from "../constants/RunningDays";
import { RunningDaysLabels } from "../constants/RunningDaysLabels";
import React from "react";
import {
  Datagrid,
  Edit,
  Filter,
  FunctionField,
  List,
  NumberInput,
  ReferenceField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput
} from "react-admin";

const PostageFilters = props => (
  <Filter {...props}>
    <TextInput label="Search by school" source="school" alwaysOn />
  </Filter>
);

export const PostageList = props => (
  <List {...props} filters={<PostageFilters />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <FunctionField
        label="Delivery"
        render={record =>
          `${record.deliveryTime.from} to ${record.deliveryTime.to} days (${
            RunningDaysLabels[record.runningDays]
          })`
        }
      />
      <FunctionField
        label="Price"
        render={record => `£${record.price / 100}`}
      />
      <ReferenceField label="School" source="school" reference="schools">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const PostageEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <NumberInput source="price" label="Price (pence)" />
      <NumberInput label="Delivery Time - from" source="deliveryTime.from" />
      <NumberInput label="Delivery Time - to" source="deliveryTime.to" />
      <SelectInput
        label="Running Days"
        source="runningDays"
        choices={[
          {
            id: RunningDays.WORKING_DAYS,
            name: RunningDaysLabels[RunningDays.WORKING_DAYS]
          },
          {
            id: RunningDays.CALENDAR_DAYS,
            name: RunningDaysLabels[RunningDays.CALENDAR_DAYS]
          }
        ]}
      />
      <ReferenceField label="School" source="school" reference="schools">
        <TextField source="name" />
      </ReferenceField>
    </SimpleForm>
  </Edit>
);
