import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import PropTypes from "prop-types";
import React from "react";
import { showNotification } from "react-admin";
import { connect } from "react-redux";
import xlsx from "xlsx";
import { axiosInstance } from "../config/axios";

class VatReport extends React.Component {
  constructor(props) {
    super(props);
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.state = {
      startDateTime: new Date(),
      endDateTime: tomorrow,
      onlyWantsUnpaid: false,
      paymentSchedule: false
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleOnlyWantsUnpaid = this.handleOnlyWantsUnpaid.bind(this);
  }
  writeCSV(data) {
    var ws = xlsx.utils.json_to_sheet(data, {
      header: [
        "_id",
        "sessionDate",
        "adults",
        "buyerEmail",
        "buyerName",
        "cancelled",
        "childPrice",
        "children",
        "courseID",
        "courseTitle",
        "createdAt",
        "discountCode",
        "discountType",
        "discountValue",
        "discountIsFromTeacher",
        "newStartDateTime",
        "price",
        "schoolID",
        "schoolBookingCommission",
        "schoolGroupBookingCommission",
        "schoolMsp",
        "schoolName",
        "schoolTopTeacher",
        "sessionObbyDiscount",
        "sessionPartnerDiscount",
        "sessionStartDateTime",
        "source",
        "stripeResultAmount",
        "stripeResultDiscountType",
        "studentPrice",
        "students",
        "blockBookingStartDate",
        "total",
        "updatedAt",
        "utm"
      ]
    });
    var wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "SheetJS");
    let fileName = `VATREPORT-extractOf- ${this.state.startDateTime}-${this.state.endDateTime}.xlsx`;
    xlsx.writeFile(wb, fileName);
  }

  handleExtract = async () => {
    const { showNotification } = this.props;
    try {
      let results = await axiosInstance.post("actions/generateVATExtract", {
        startDateTime: this.state.startDateTime,
        endDateTime: this.state.endDateTime
      });
      console.log("the data ", results.data);
      this.writeCSV(results.data.finalResults);
      showNotification("Extract were generated and it will start the download");
    } catch (error) {
      showNotification(error.message, "error");
    }
  };

  handleDateChange = date => {
    this.setState({ startDateTime: date });
  };
  handleEndDateChange = date => {
    this.setState({ endDateTime: date });
  };
  handleOnlyWantsUnpaid = () => event => {
    this.setState({
      onlyWantsUnpaid: event.target.checked
    });
  };

  handlePaymentSchedule = event => {
    this.setState({
      paymentSchedule: event.target.value
    });
  };

  render() {
    return (
      <Card>
        <CardHeader title="Extract VAT Report" />
        <CardContent>
          <p>
            Choose the startDate and endDate and click the button: 'Extract'
          </p>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              value={this.state.startDateTime}
              label="Start Date"
              onChange={this.handleDateChange}
            />
            <span style={{ margin: "10px" }} />
            <DatePicker
              value={this.state.endDateTime}
              label="End Date"
              onChange={this.handleEndDateChange}
            />
          </MuiPickersUtilsProvider>

          <Button color="primary" onClick={this.handleExtract}>
            Extract
          </Button>
        </CardContent>
      </Card>
    );
  }
}

VatReport.propTypes = {
  showNotification: PropTypes.func
};

export default connect(null, {
  showNotification
})(VatReport);
