import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconCancel from "@material-ui/icons/Cancel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { Component, Fragment } from "react";
import {
  Button,
  CREATE,
  crudGetMatching,
  fetchEnd,
  fetchStart,
  SaveButton,
  showNotification,
  Loading
} from "react-admin";
import { connect } from "react-redux";
import { change, isSubmitting, submit } from "redux-form";
import dataProvider from "../dataProvider";
import { axiosInstance } from "../config/axios";

class BulkTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      showDialog: false,
      isSubmitting: false,
      sendCustomerEmail: false,
      sourceTagsIDs: [],
      allTags: []
    };
  }

  async componentDidMount() {
    console.log("component did mount");
    const response = await axiosInstance.get(`/actions/get-tags-for-bulk-tag`);
    console.log("the response ", response);
    this.setState({
      allTags: response.data
    });
  }

  handleCloseClick = () => {
    // reset the start date time
    this.setState({
      showDialog: false
    });
  };

  handleSaveClick = () => {
    const { fetchStart, fetchEnd, showNotification } = this.props;
    fetchStart();
    this.setState({
      isSubmitting: true
    });
    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    let sourceTagsIDs = this.state.sourceTagsIDs.map(tag => {
      return tag.id;
    });
    dataProvider(CREATE, "actions/tag/bulkTag", {
      data: {
        tagID: this.props.data._id,
        sourceTagsIDs: sourceTagsIDs
      }
    })
      .then(({ data }) => {
        this.setState({ showDialog: false });
        //hacky way to reload the data
        window.location.reload();
      })
      .catch(error => {
        showNotification(error.message, "error");
      })
      .finally(() => {
        this.setState({
          isSubmitting: false
        });
        fetchEnd();
      });
  };

  handleClick = () => {
    this.setState({
      showDialog: true
    });
  };

  handleChangeAutoComplete = (event, newValue) => {
    console.log("handleChangeAutoComplete ", newValue);
    this.setState({
      sourceTagsIDs: newValue
    });
  };

  render() {
    const { showDialog } = this.state;
    const { isSubmitting } = this.state;
    return (
      <Fragment>
        <Button onClick={this.handleClick.bind(this)} label="Bulk Tag" />
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick.bind(this)}
        >
          <DialogTitle>Refund booking</DialogTitle>
          <DialogContent>
            <Autocomplete
              multiple
              id="tags-standard"
              options={this.state.allTags}
              getOptionLabel={option => option.name}
              onChange={this.handleChangeAutoComplete.bind(this)}
              filterSelectedOptions
              renderInput={params => (
                <TextField {...params} variant="standard" label="Tags" />
              )}
            />
          </DialogContent>
          <DialogActions>
            <SaveButton
              label="Tag content"
              saving={isSubmitting}
              handleSubmitWithRedirect={this.handleSaveClick.bind(this)}
            />
            <Button
              label="ra.action.cancel"
              onClick={this.handleCloseClick.bind(this)}
            >
              <IconCancel />
            </Button>
          </DialogActions>
          {this.state.isSubmitting && <Loading />}
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting("post-quick-create")(state)
});

const mapDispatchToProps = {
  change,
  crudGetMatching,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkTag);
