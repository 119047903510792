import React from "react";
import {
    Create, Datagrid, DateField, Edit, Filter, FormTab, List, ReferenceField, SelectInput, SimpleForm, TabbedForm, TextField, TextInput
} from "react-admin";

const Tag2TagFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by tag ID" source="qTagID" />
    <SelectInput
      label="Search by Relantion Type"
      source="qRelantionType"
      choices={[
        { id: "parent", name: "parent" },
        { id: "similar", name: "similar" }
      ]}
    />
  </Filter>
);

export const Tag2TagList = props => (
  <List {...props} filters={<Tag2TagFilter />}>
    <Datagrid rowClick="edit">
      <ReferenceField label="leftTag" source="leftTag" reference="tags">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="rightTag" source="rigthTag" reference="tags">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="relationship" source="relation" />
      <DateField label="Created Date" source="createdAt" locales="en-GB" />
    </Datagrid>
  </List>
);

export const Tag2TagCreate = props => (
  <Create {...props}>
    <SimpleForm></SimpleForm>
  </Create>
);

export const Tag2TagEdit = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="General info">
        <ReferenceField label="leftTag" source="leftTag" reference="tags">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="rightTag" source="rigthTag" reference="tags">
          <TextField source="name" />
        </ReferenceField>
        <TextField label="relationship" source="relation" />
        <DateField label="Created Date" source="createdAt" locales="en-GB" />
      </FormTab>
    </TabbedForm>
  </Edit>
);
