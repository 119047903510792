import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import PropTypes from "prop-types";
import React from "react";
import { showNotification } from "react-admin";
import { connect } from "react-redux";
import xlsx from "xlsx";
import { axiosInstance } from "../config/axios";

class GeneratePayout extends React.Component {
  constructor(props) {
    super(props);
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.state = {
      startDateTime: new Date(),
      endDateTime: tomorrow,
      onlyWantsUnpaid: false,
      paymentSchedule: false,
      includeStripeSchools: false
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleOnlyWantsUnpaid = this.handleOnlyWantsUnpaid.bind(this);
    this.handleIncludeStripeSchools = this.handleIncludeStripeSchools.bind(
      this
    );
  }
  writeBookingsCSV(data) {
    var ws = xlsx.utils.json_to_sheet(data, {
      header: [
        "_id",
        "sessionDate",
        "adults",
        "totalAttendants",
        "buyerEmail",
        "buyerName",
        "cancelled",
        "childPrice",
        "children",
        "courseID",
        "courseTitle",
        "createdAt",
        "discountCode",
        "discountType",
        "discountValue",
        "discountIsFromTeacher",
        "newStartDateTime",
        "price",
        "schoolID",
        "schoolBookingCommission",
        "schoolGroupBookingCommission",
        "schoolMsp",
        "schoolName",
        "schoolTopTeacher",
        "sessionObbyDiscount",
        "sessionPartnerDiscount",
        "sessionStartDateTime",
        "source",
        "stripeResultAmount",
        "stripeResultDiscountType",
        "studentPrice",
        "students",
        "blockBookingStartDate",
        "total",
        "updatedAt",
        "utm"
      ]
    });
    var wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "SheetJS");
    let fileName = `Bookings-extractOf- ${this.state.startDateTime}-${this.state.endDateTime}.xlsx`;
    xlsx.writeFile(wb, fileName);
  }

  writeProductsCSV(data) {
    var ws = xlsx.utils.json_to_sheet(data, {
      header: [
        "_id",
        "sessionDate",
        "adults",
        "totalAttendants",
        "buyerEmail",
        "buyerName",
        "cancelled",
        "childPrice",
        "children",
        "courseID",
        "courseTitle",
        "createdAt",
        "discountCode",
        "discountType",
        "discountValue",
        "discountIsFromTeacher",
        "newStartDateTime",
        "price",
        "schoolID",
        "schoolBookingCommission",
        "schoolGroupBookingCommission",
        "schoolMsp",
        "schoolName",
        "schoolTopTeacher",
        "sessionObbyDiscount",
        "sessionPartnerDiscount",
        "sessionStartDateTime",
        "source",
        "stripeResultAmount",
        "stripeResultDiscountType",
        "studentPrice",
        "students",
        "blockBookingStartDate",
        "total",
        "updatedAt",
        "utm"
      ]
    });
    var wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "SheetJS");
    let fileName = `Products-extractOf- ${this.state.startDateTime}-${this.state.endDateTime}.xlsx`;
    xlsx.writeFile(wb, fileName);
  }
  handleExtract = async () => {
    const { showNotification } = this.props;
    try {
      let results = await axiosInstance.post("actions/generateExtract", {
        startDateTime: this.state.startDateTime,
        endDateTime: this.state.endDateTime,
        onlyWantsUnpaid: this.state.onlyWantsUnpaid,
        paymentSchedule: this.state.paymentSchedule,
        includeStripeSchools: this.state.includeStripeSchools
      });
      console.log("results ", results);
      this.writeBookingsCSV(results.data.bookings);
      this.writeProductsCSV(results.data.products);
      showNotification("Extract were generated and it will start the download");
    } catch (error) {
      showNotification(error.message, "error");
    }
  };

  handleDateChange = date => {
    this.setState({ startDateTime: date });
  };
  handleEndDateChange = date => {
    this.setState({ endDateTime: date });
  };
  handleOnlyWantsUnpaid = () => event => {
    this.setState({
      onlyWantsUnpaid: event.target.checked
    });
  };
  handleIncludeStripeSchools = () => event => {
    this.setState({
      includeStripeSchools: event.target.checked
    });
  };

  handlePaymentSchedule = event => {
    this.setState({
      paymentSchedule: event.target.value
    });
  };

  render() {
    return (
      <Card>
        <CardHeader title="Extract bookings for payments" />
        <CardContent>
          <p>
            Choose the startDate and endDate and click the button: 'Extract'
          </p>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              value={this.state.startDateTime}
              label="Start Date"
              onChange={this.handleDateChange}
            />
            <span style={{ margin: "10px" }} />
            <DatePicker
              value={this.state.endDateTime}
              label="End Date"
              onChange={this.handleEndDateChange}
            />
          </MuiPickersUtilsProvider>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.onlyWantsUnpaid}
                onChange={this.handleOnlyWantsUnpaid()}
              />
            }
            label="Only extract unpaid bookings"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.includeStripeSchools}
                onChange={this.handleIncludeStripeSchools()}
              />
            }
            label="Include School with Stripe Connects"
          />
          <FormControl>
            <InputLabel htmlFor="age-simple">P. Schedule</InputLabel>
            <Select
              value={this.state.paymentSchedule}
              onChange={this.handlePaymentSchedule}
              style={{ width: "90px" }}
            >
              <MenuItem value={false}>All</MenuItem>
              <MenuItem value={"weekly"}>Weekly</MenuItem>
              <MenuItem value={"monthly"}>Monthly</MenuItem>
            </Select>
          </FormControl>
          <Button color="primary" onClick={this.handleExtract}>
            Extract
          </Button>
        </CardContent>
      </Card>
    );
  }
}

GeneratePayout.propTypes = {
  showNotification: PropTypes.func
};

export default connect(null, {
  showNotification
})(GeneratePayout);
