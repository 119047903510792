import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import React from "react";
import { fetchEnd, GET_MANY, showNotification } from "react-admin";
import { connect } from "react-redux";
import xlsx from "xlsx";
import dataProvider from "../dataProvider";

class ExtractBankDetails extends React.Component {
  writeCSV(data) {
    var ws = xlsx.utils.json_to_sheet(data, {
      header: [
        "School ID",
        "School Name",
        "Sort Code",
        "Account Number",
        "Email",
        "Name"
      ]
    });
    var wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "SheetJS");
    let fileName = `BankDetails- ${+new Date()}.xlsx`;
    xlsx.writeFile(wb, fileName);
  }
  handleClick = () => {
    const { showNotification } = this.props;
    showNotification("Extract will happen in some seconds");
    dataProvider(GET_MANY, "actions/extract-bank-details", {})
      .then(response => {
        console.log("the response ", response);
        this.writeCSV(response.data);
        //showNotification("Extract will happen");
      })
      .catch(error => {
        console.log("we go an error ", error);
        //showNotification(error.message, "error");
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  render() {
    return (
      <Card>
        <CardHeader title="Extract bank details" />
        <CardContent>
          <p>Extract the bank details from the schools</p>
          <Button color="primary" onClick={this.handleClick}>
            Extract
          </Button>
        </CardContent>
      </Card>
    );
  }
}

export default connect(null, {
  showNotification
})(ExtractBankDetails);
