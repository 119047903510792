import React from "react";
import {
  Button,
  CardActions,
  CREATE,
  fetchEnd,
  fetchStart,
  showNotification,
  SaveButton
} from "react-admin";
import { connect } from "react-redux";
import { axiosInstance } from "../config/axios";
import dataProvider from "../dataProvider";
import { MarkSchoolAsSoldOutButton } from "../actions/MarkSchoolAsSoldOutButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconCancel from "@material-ui/icons/Cancel";

class SchoolActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      showDialog: false,
      isSubmitting: false
    };
  }

  onEnableStripeDomain() {
    this.update("enable-stripe-domain");
  }

  onUpdateBookingsAccountingClick() {
    this.update("update-bookings-accounting");
  }

  onApproveSchool() {
    this.update("approve-school");
  }

  onApproveUserSchool() {
    this.update("approve-user-school");
  }

  onWidgetActivation() {
    this.update("activate-widget-school");
  }

  onArchivingClass() {
    this.update("archive-all-classes-school");
  }

  onHideAllClasses() {
    this.update("hide-all-classes-school");
  }

  onSyncActiveCampaignClick() {
    this.update("sync-school-active-campaign");
  }
  onDeleteStripeConnectedAccount() {
    this.update("admin-delete-stripe-connected-account");
  }
  onUnlinkStripeConnectedAccount() {
    this.update("unlink-stripe-connected-account");
  }
  onFraudulentSchool() {
    this.update("mark-school-as-fraudulent");
  }

  async onGetCustomersCSVOfSchool() {
    this.setState({
      updating: true
    });
    const response = await axiosInstance.get(
      `/actions/get-customers-csv-of-school/${this.props.data._id}`
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    const fileName = `CustomerDataForSchool-${+new Date()}.csv`; // whatever your file name .
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  onShowDialog() {
    this.setState({
      showDialog: true
    });
  }

  onHideDialog() {
    this.setState({
      showDialog: false
    });
  }

  render() {
    const { updating } = this.state;
    return (
      <CardActions>
        <MarkSchoolAsSoldOutButton school={this.props.data} />
        <Button
          disabled={updating}
          onClick={this.onGetCustomersCSVOfSchool.bind(this)}
          label={"Get School customers"}
        />
        <Button
          disabled={updating}
          onClick={this.onWidgetActivation.bind(this)}
          label={"Activate widget"}
        />
        <Button
          disabled={updating}
          onClick={this.onApproveUserSchool.bind(this)}
          label={"Requires Changes"}
        />
        <Button
          disabled={updating}
          onClick={this.onApproveSchool.bind(this)}
          label={"Activate school"}
        />
        <Button
          disabled={updating}
          onClick={this.onUpdateBookingsAccountingClick.bind(this)}
          label={"Update Bookings Accounting"}
        />
        <Button
          disabled={updating}
          onClick={this.onSyncActiveCampaignClick.bind(this)}
          label={"Sync Active Campaign"}
        />
        <Button
          disabled={updating}
          onClick={this.onArchivingClass.bind(this)}
          label={"Archive classes"}
        />
        <Button
          disabled={updating}
          onClick={this.onShowDialog.bind(this)}
          label={"Remove school"}
        />

        <Dialog
          fullWidth
          open={this.state.showDialog}
          onClose={this.onHideDialog.bind(this)}
          aria-label="Create post"
        >
          <DialogTitle>Are you sure you want delete this School?</DialogTitle>
          <DialogContent>
            This will disable access to the users associated to school. Will
            remove the school, cancel the subscription and downgrade to free.
          </DialogContent>
          <DialogActions>
            <SaveButton
              label="Yes,i am sure."
              saving={updating}
              handleSubmitWithRedirect={this.onHideAllClasses.bind(this)}
            />
            <Button label="Cancel" onClick={this.onHideDialog.bind(this)}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          disabled={updating}
          onClick={this.onDeleteStripeConnectedAccount.bind(this)}
          label={"Delete Stripe Connected Account"}
        />
        <Button
          disabled={updating}
          onClick={this.onUnlinkStripeConnectedAccount.bind(this)}
          label={"Unlink School From Stripe Connected Account"}
        />
        <Button
          disabled={updating}
          onClick={this.onFraudulentSchool.bind(this)}
          label="Disable school from being a fraud"
        />
        <Button
          disabled={updating}
          onClick={this.onEnableStripeDomain.bind(this)}
          label="Enable Stripe Domain"
        />
      </CardActions>
    );
  }

  update(action) {
    this.setState({
      updating: true
    });
    fetchStart();
    dataProvider(CREATE, `actions/${action}`, {
      data: { schoolId: this.props.data._id }
    })
      .then(response => {
        this.props.showNotification("Updated successfully", "success");
        window.location.reload();
      })
      .catch(error => {
        this.props.showNotification(error.message, "error");
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
        this.setState({
          updating: false
        });
      });
  }
}

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification
};

export default connect(null, mapDispatchToProps)(SchoolActions);
