import React from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  CardActions,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  Edit,
  ExportButton,
  Filter,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  RefreshButton,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  TabbedForm,
  FormTab
} from "react-admin";
import { PapertrailReferenceTable } from "../components/PapertrailReferenceTable";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);
const ConditionalCreatedByField = ({ record, ...rest }) =>
  record && record.createdBy !== "" ? (
    <TextInput source="createdBy" />
  ) : (
    <div>Undefined created by</div>
  );

const DiscountFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by discount code" source="qCode" alwaysOn />
    <TextInput label="Search by Used By (email)" source="qUsedEmail" />
    <TextInput label="Search by createdBy" source="qCreatedBy" />
  </Filter>
);

export const DiscountList = props => (
  <List
    {...props}
    filters={<DiscountFilter />}
    sort={{ field: "createdAt", order: "DESC" }}
    actions={<PostActions />}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Code" source="code" />
      <DateField label="Created date" source="createdAt" locales="en-GB" />
      <TextField label="Value" source="value" />
      <TextField label="Type" source="type" />
    </Datagrid>
  </List>
);

export const DiscountCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Code" source="code" />
      <NumberInput label="Value" source="value" />
      <AutocompleteInput
        label="Type"
        source="type"
        choices={[
          { id: "PERCENTAGE", name: "Percentage" },
          { id: "PRICE", name: "Price" }
        ]}
      />
      <BooleanInput
        label="Is for multile usage?"
        source="multipleUsageGiftCard"
      />
      <AutocompleteInput
        label="Nature"
        source="nature"
        choices={[
          { id: "DISCOUNT", name: "DISCOUNT" },
          { id: "GIFTCARD", name: "GIFTCARD" },
          { id: "SALES", name: "SALES" },
          { id: "REVIEW", name: "REVIEW" }
        ]}
      />
      <NumberInput label="Number (0 = infinite)" source="total" />
      <BooleanInput label="Partner-funded" source="isFromTeacher" />
      <BooleanInput label="Has Restriction enabled?" source="hasRestrictions" />
      <AutocompleteInput
        label="restrictionItemType"
        source="restrictionItemType"
        choices={[
          { id: "class", name: "class" },
          { id: "product", name: "product" }
        ]}
      />
      <BooleanInput label="Valid on all products?" source="isForAllProducts" />
      <BooleanInput label="Valid on all classes" source="isForAllClasses" />
      <ReferenceInput
        label="School"
        source="schoolID"
        reference="schools"
        perPage={25}
        filterToQuery={searchText => ({ qName: searchText })}
      >
        <AutocompleteInput
          optionText="name"
          allowEmpty={true}
          shouldRenderSuggestions={val => {
            return val.trim().length >= 2;
          }}
          highlightFirstSuggestion={false}
          inputValueMatcher={() => null}
          options={{
            fullWidth: true,
            suggestionsContainerProps: {
              disablePortal: false
            }
          }}
        />
      </ReferenceInput>
      <ReferenceInput
        label="Course"
        source="courseID"
        reference="courses"
        perPage={25}
        filterToQuery={searchText => ({ qName: searchText })}
      >
        <AutocompleteInput
          optionText="title"
          allowEmpty={true}
          shouldRenderSuggestions={val => {
            return val.trim().length >= 2;
          }}
          highlightFirstSuggestion={false}
          inputValueMatcher={() => null}
          options={{
            fullWidth: true,
            suggestionsContainerProps: {
              disablePortal: false
            }
          }}
        />
      </ReferenceInput>
      <DateTimeInput label="Starts at" source="startsAt" locales="en-GB" />
      <DateTimeInput label="Ends at" source="endsAt" locales="en-GB" />
    </SimpleForm>
  </Create>
);

export const DiscountEdit = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="General">
        <TextInput label="Id" source="id" disabled />
        <TextInput label="Nature" source="nature" disabled />
        <TextInput label="Code" source="code" />
        <NumberInput source="value" />
        <AutocompleteInput
          source="type"
          choices={[
            { id: "PERCENTAGE", name: "Percentage" },
            { id: "PRICE", name: "Price" }
          ]}
        />
        <NumberInput label="Number" source="total" />
        <ArrayInput source="usedBy">
          <SimpleFormIterator>
            <TextInput />
          </SimpleFormIterator>
        </ArrayInput>
        <DateInput label="Created at" source="createdAt" />
        <ReferenceField
          label="AdminUser link"
          source="createdBy"
          reference="users"
        >
          <TextField source="email" />
        </ReferenceField>
        <ConditionalCreatedByField />
        <BooleanInput label="Disable" source="disabled" />
        <BooleanInput label="Partner-funded" source="isFromTeacher" />
        <BooleanInput
          label="Has Restriction enabled?"
          source="hasRestrictions"
        />
        <AutocompleteInput
          label="restrictionItemType"
          source="restrictionItemType"
          choices={[
            { id: "class", name: "class" },
            { id: "product", name: "product" }
          ]}
        />
        <BooleanInput
          label="Valid on all products?"
          source="isForAllProducts"
        />
        <BooleanInput label="Valid on all classes?" source="isForAllClasses" />
        <ReferenceInput
          label="School"
          source="schoolID"
          reference="schools"
          perPage={25}
          filterToQuery={searchText => ({ qName: searchText })}
        >
          <AutocompleteInput
            optionText="name"
            allowEmpty={true}
            shouldRenderSuggestions={val => {
              return val.trim().length >= 2;
            }}
            highlightFirstSuggestion={false}
            inputValueMatcher={() => null}
            options={{
              fullWidth: true,
              suggestionsContainerProps: {
                disablePortal: false
              }
            }}
          />
        </ReferenceInput>
        <ReferenceInput
          label="Course"
          source="courseID"
          reference="courses"
          perPage={25}
          filterToQuery={searchText => ({ qName: searchText })}
        >
          <AutocompleteInput
            optionText="title"
            allowEmpty={true}
            shouldRenderSuggestions={val => {
              return val.trim().length >= 2;
            }}
            highlightFirstSuggestion={false}
            inputValueMatcher={() => null}
            options={{
              fullWidth: true,
              suggestionsContainerProps: {
                disablePortal: false
              }
            }}
          />
        </ReferenceInput>
        <DateTimeInput label="Starts at" source="startsAt" locales="en-GB" />
        <DateTimeInput label="Ends At" source="endsAt" locales="en-GB" />
      </FormTab>

      <FormTab label="Papertrail">
        <PapertrailReferenceTable />
      </FormTab>
    </TabbedForm>
  </Edit>
);
