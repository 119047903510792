import React from "react";
import {
    Create, Datagrid, DateField, Edit, Filter, List, NumberField,
    NumberInput, ReferenceField, SelectInput, SimpleForm, TextField, TextInput
} from "react-admin";

const PrivateTagFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by name" source="q" alwaysOn />
  </Filter>
);

export const PrivateTagList = props => (
  <List {...props} filters={<PrivateTagFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField label="School Link" source="school" reference="schools">
        <TextField source="name" />
      </ReferenceField>
      <NumberField
        source="numberOfContentAssociatedToThisTag"
        label="Content Associated"
      />
      <DateField label="Created Date" source="createdAt" locales="en-GB" />
    </Datagrid>
  </List>
);

export const PrivateTagCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="school" label="School ID" />
      <TextInput source="user" label="Teacher User ID ID" />
    </SimpleForm>
  </Create>
);

export const PrivateTagEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput label="Id" source="id" disabled />
      <TextInput source="name" />
      <TextInput source="slug" disabled />
      <SelectInput
        source="status"
        choices={[
          { id: "enabled", name: "enabled" },
          { id: "waitingforapproval", name: "waitingforapproval" },
          { id: "disabled", name: "disabled" }
        ]}
      />
      <ReferenceField label="School Link" source="school" reference="schools">
        <TextField source="name" />
      </ReferenceField>
      <NumberInput
        source="numberOfContentAssociatedToThisTag"
        label="Content associated to this tag"
      />
      <DateField label="Created Date" source="createdAt" locales="en-GB" />
      <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
    </SimpleForm>
  </Edit>
);
