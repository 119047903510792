// in src/App.js
import React, { useEffect } from "react";
import { Admin, Resource } from "react-admin";
import { auth } from "./config/auth";
import AdminLayout from "./AdminLayout";
import authProvider from "./authProvider";
import AlgoliaShow from "./dashboard/dashboard";
import dataProvider from "./dataProvider";
import { i18nProvider } from "./i18nProvider";
import { AdminUserEdit, AdminUserList } from "./resources/adminUser";
import { BookingCreate, BookingEdit, BookingList } from "./resources/booking";
import {
  CategoryCreate,
  CategoryEdit,
  CategoryList
} from "./resources/category";
import {
  ClassListCreate,
  ClassListEdit,
  ClassListList
} from "./resources/classList";
import { CourseCreate, CourseEdit, CourseList } from "./resources/course";
import {
  BlockInstanceCreate,
  BlockInstanceEdit,
  BlockInstanceList
} from "./resources/blockInstance";
import {
  BlockStructureCreate,
  BlockStructureEdit,
  BlockStructureList
} from "./resources/blockStructure";
import {
  DateNotificationRequestsCreate,
  DateNotificationRequestsEdit,
  DateNotificationRequestsList
} from "./resources/dateNotificationRequests";
import {
  DiscountCreate,
  DiscountEdit,
  DiscountList
} from "./resources/discount";
import { ObbyFaqEdit, ObbyFaqCreate, ObbyFaqList } from "./resources/obbyFaq";
import { GeoTagCreate, GeoTagEdit, GeoTagList } from "./resources/geoTag";
import {
  GiftcardCreate,
  GiftcardEdit,
  GiftcardList
} from "./resources/giftcard";
import {
  GroupRequestCreate,
  GroupRequestEdit,
  GroupRequestList
} from "./resources/groupRequest";
import {
  LearnContentPageCreate,
  LearnContentPageEdit,
  LearnContentPageList
} from "./resources/learnContentPages";
import { MessageCreate, MessageEdit, MessageList } from "./resources/message";
import {
  PartnerLocationCreate,
  PartnerLocationEdit,
  PartnerLocationList
} from "./resources/partnerLocation";
import { PapertrailEdit, PapertrailList } from "./resources/paperTrail";
import { PayoutEdit, PayoutList } from "./resources/payouts";
import { PostCreate, PostEdit, PostList } from "./resources/post";
import { PostageEdit, PostageList } from "./resources/postage";
import {
  PrivateTagCreate,
  PrivateTagEdit,
  PrivateTagList
} from "./resources/privateTag";
import { ProductEdit, ProductList } from "./resources/product";
import {
  ProductPurchasesEdit,
  ProductPurchasesList
} from "./resources/productPurchases";
import { ReviewEdit, ReviewList } from "./resources/review";
import { SchoolCreate, SchoolEdit, SchoolList } from "./resources/school";
import { SessionEdit, SessionList } from "./resources/session";
import {
  SubCategoryCreate,
  SubCategoryEdit,
  SubCategoryList
} from "./resources/subcategories";
import { TagCreate, TagEdit, TagList } from "./resources/tag";
import { Tag2TagCreate, Tag2TagEdit, Tag2TagList } from "./resources/tag2tag";
import {
  TeacherPlanCreate,
  TeacherPlanEdit,
  TeacherPlanList
} from "./resources/teacherPlan";
import { TicketTypeEdit, TicketTypeList } from "./resources/ticketType";
import { UserCreate, UserEdit, UserList } from "./resources/user";
import { PrivateClassEdit, PrivateClassList } from "./resources/privateClasses";
import {
  WaitingListRecordList,
  WaitingListRecordEdit
} from "./resources/waitingListRecord";
import {
  PrivatePaymentsEdit,
  PrivatePaymentsList
} from "./resources/privatePayments";
import { ServicesEdit, ServicesList } from "./resources/services";
import {
  OrderItemPurchaseList,
  OrderItemPurchaseEdit
} from "./resources/orderItemPurchase";
import {
  PhysicalGiftcardEdit,
  PhysicalGiftcardList
} from "./resources/physicalGiftcard";
import {
  PaymentIntentList,
  PaymentIntentEdit
} from "./resources/paymentIntent";
import { CustomerList, CustomerEdit } from "./resources/customer";
import {
  SchoolCustomerEdit,
  SchoolCustomerList
} from "./resources/schoolCustomer";

export default function App() {
  useEffect(() => {
    window.token = auth.getToken();
  }, []);

  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      appLayout={AdminLayout}
      i18nProvider={i18nProvider}
      dashboard={AlgoliaShow}
    >
      <Resource
        name="bookings"
        list={BookingList}
        create={BookingCreate}
        edit={BookingEdit}
      />
      <Resource
        name="productPurchases"
        list={ProductPurchasesList}
        edit={ProductPurchasesEdit}
      />
      <Resource
        name="privatePayments"
        list={PrivatePaymentsList}
        edit={PrivatePaymentsEdit}
      />
      <Resource
        name="giftcards"
        list={GiftcardList}
        create={GiftcardCreate}
        edit={GiftcardEdit}
      />
      <Resource
        name="users"
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
      />
      <Resource
        name="paymentIntents"
        list={PaymentIntentList}
        edit={PaymentIntentEdit}
      />
      <Resource
        name="learnContentPages"
        list={LearnContentPageList}
        create={LearnContentPageCreate}
        edit={LearnContentPageEdit}
      />
      <Resource name="customers" list={CustomerList} edit={CustomerEdit} />
      <Resource
        name="schoolCustomers"
        list={SchoolCustomerList}
        edit={SchoolCustomerEdit}
      />

      <Resource name="tags" list={TagList} create={TagCreate} edit={TagEdit} />
      <Resource
        name="privateTags"
        list={PrivateTagList}
        create={PrivateTagCreate}
        edit={PrivateTagEdit}
      />
      <Resource
        name="geoTags"
        list={GeoTagList}
        create={GeoTagCreate}
        edit={GeoTagEdit}
      />
      <Resource
        name="discounts"
        list={DiscountList}
        create={DiscountCreate}
        edit={DiscountEdit}
      />
      <Resource
        name="schools"
        list={SchoolList}
        create={SchoolCreate}
        edit={SchoolEdit}
      />
      <Resource
        name="courses"
        list={CourseList}
        create={CourseCreate}
        edit={CourseEdit}
      />
      <Resource
        name="blockInstances"
        list={BlockInstanceList}
        create={BlockInstanceCreate}
        edit={BlockInstanceEdit}
      />
      <Resource
        name="blockStructures"
        list={BlockStructureList}
        create={BlockStructureCreate}
        edit={BlockStructureEdit}
      />
      <Resource name="products" list={ProductList} edit={ProductEdit} />
      <Resource name="postage" list={PostageList} edit={PostageEdit} />
      <Resource name="sessions" list={SessionList} edit={SessionEdit} />
      <Resource
        name="physicalGiftcards"
        list={PhysicalGiftcardList}
        edit={PhysicalGiftcardEdit}
      />
      <Resource
        name="ticketTypes"
        list={TicketTypeList}
        edit={TicketTypeEdit}
      />
      <Resource
        name="plans"
        list={TeacherPlanList}
        create={TeacherPlanCreate}
        edit={TeacherPlanEdit}
      />
      <Resource name="reviews" list={ReviewList} edit={ReviewEdit} />
      <Resource
        name="privateClasses"
        list={PrivateClassList}
        edit={PrivateClassEdit}
      />
      <Resource
        name="tags2tags"
        list={Tag2TagList}
        create={Tag2TagCreate}
        edit={Tag2TagEdit}
      />
      <Resource
        name="classLists"
        list={ClassListList}
        create={ClassListCreate}
        edit={ClassListEdit}
      />
      <Resource name="payouts" list={PayoutList} edit={PayoutEdit} />
      <Resource
        name="categories"
        list={CategoryList}
        create={CategoryCreate}
        edit={CategoryEdit}
      />
      <Resource
        name="dateNotificationRequests"
        list={DateNotificationRequestsList}
        create={DateNotificationRequestsCreate}
        edit={DateNotificationRequestsEdit}
      />

      <Resource
        name="groupRequests"
        list={GroupRequestList}
        create={GroupRequestCreate}
        edit={GroupRequestEdit}
      />
      <Resource
        name="messages"
        list={MessageList}
        create={MessageCreate}
        edit={MessageEdit}
      />
      <Resource
        name="partnerLocations"
        list={PartnerLocationList}
        create={PartnerLocationCreate}
        edit={PartnerLocationEdit}
      />
      <Resource
        name="posts"
        list={PostList}
        create={PostCreate}
        edit={PostEdit}
      />
      <Resource
        name="subcategories"
        list={SubCategoryList}
        create={SubCategoryCreate}
        edit={SubCategoryEdit}
      />
      <Resource name="adminUsers" list={AdminUserList} edit={AdminUserEdit} />
      <Resource
        name="obbyFaqs"
        list={ObbyFaqList}
        create={ObbyFaqCreate}
        edit={ObbyFaqEdit}
      />
      <Resource
        name="waitingListRecords"
        list={WaitingListRecordList}
        edit={WaitingListRecordEdit}
      />
      <Resource
        name="orderItemPurchases"
        list={OrderItemPurchaseList}
        edit={OrderItemPurchaseEdit}
      />

      <Resource name="services" list={ServicesList} edit={ServicesEdit} />
      <Resource name="papertrail" list={PapertrailList} edit={PapertrailEdit} />
    </Admin>
  );
}
