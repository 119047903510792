import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput,
  Filter,
  SelectInput
} from "react-admin";

function ObbyFaqFilter(props) {
  return (
    <Filter {...props}>
      <SelectInput
        label="Type"
        source="qType"
        choices={[{ id: "quickstart", name: "quickstart" }]}
        alwaysOn
      />
      <SelectInput
        label="Company"
        source="qCompany"
        choices={[
          { id: "obby", name: "Obby" },
          { id: "baluu", name: "Baluu" }
        ]}
        alwaysOn
      />
    </Filter>
  );
}
export function ObbyFaqList(props) {
  return (
    <List
      {...props}
      filters={<ObbyFaqFilter />}
      sort={{ field: "order", order: "ASC" }}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="question" />
        <TextField source="answer" />
        <TextField source="type" />
        <TextField source="company" />
        <TextField source="order" />
      </Datagrid>
    </List>
  );
}

export const ObbyFaqEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="question" fullWidth />
      <TextInput source="answer" multiline fullWidth />
      <SelectInput
        source="type"
        choices={[
          { id: "quickstart", name: "quickstart" },
          { id: "pricing", name: "pricing" }
        ]}
      />
      <SelectInput
        source="company"
        choices={[
          { id: "obby", name: "Obby" },
          { id: "baluu", name: "Baluu" }
        ]}
      />
      <TextField source="type" />
      <NumberInput source="order" />
    </SimpleForm>
  </Edit>
);

export function ObbyFaqCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="question" fullWidth />
        <TextInput source="answer" multiline fullWidth />
        <SelectInput
          source="type"
          choices={[
            { id: "quickstart", name: "quickstart" },
            { id: "pricing", name: "pricing" }
          ]}
          alwaysOn
        />
        <SelectInput
          source="company"
          choices={[
            { id: "obby", name: "Obby" },
            { id: "baluu", name: "Baluu" }
          ]}
        />
        <NumberInput source="order" />
      </SimpleForm>
    </Create>
  );
}
