import React from "react";
import {
  ArrayInput,
  BooleanField,
  BooleanInput,
  ChipField,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  Filter,
  FormDataConsumer,
  FormTab,
  FunctionField,
  Labeled,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  ReferenceArrayField
} from "react-admin";
import EditActions from "./../actions/OrderEditActions/index";

const FilterInstance = props => (
  <Filter {...props}>
    <TextInput label="Search by id" source="qID" />
    <TextInput label="Search by buyer email" source="qBuyerEmail" alwaysOn />
    <TextInput label="Search by buyer first name" source="qBuyerName" />
  </Filter>
);
export function PrivatePaymentsList(props) {
  return (
    <List
      {...props}
      filters={<FilterInstance />}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <Datagrid rowClick="edit">
        <TextField label="ID" source="_id" />
        <TextField label="Name" source="displayName" />
        <TextField label="Total Amount" source="displayTotal" />
        <TextField label="Buyer Name" source="buyerName" />
        <ReferenceField label="School" source="schoolID" reference="schools">
          <FunctionField render={render => render.name} />
        </ReferenceField>
        <DateField label="Created Date" source="createdAt" locales="en-GB" />
      </Datagrid>
    </List>
  );
}

function RelatedItemRow({ getSource, record }) {
  if (record.type === "booking") {
    return (
      <ReferenceField
        label="Booking"
        source={getSource("itemData.bookingId")}
        reference="bookings"
      >
        <Labeled label="Private Class (Click to edit details)">
          <ReferenceField source="privateCourse._id" reference="privateClasses">
            <TextField label="Title" source="title" />
          </ReferenceField>
        </Labeled>
      </ReferenceField>
    );
  } else if (record.type === "service") {
    return (
      <Labeled label="Service (Click to edit details)">
        <ReferenceField
          source={getSource("itemData.serviceId")}
          reference="services"
        >
          <TextField label="Service" source="name" disabled />
        </ReferenceField>
      </Labeled>
    );
  }

  return null;
}

export function PrivatePaymentsEdit(props) {
  return (
    <Edit {...props} actions={<EditActions />}>
      <TabbedForm>
        <FormTab label="Order Details">
          <TextField label="ID" source="_id" />
          <TextField label="Name" source="displayName" />
          <NumberField label="Total Amount" source="displayTotal" />
          <TextField label="User Paid" source="displayUserCharged" />
          <NumberField
            label="Amount remaning"
            source="displayAmountRemaining"
          />
          <ChipField label="User Money Status" source="userMoneyStatus" />
          <DateField label="Payment due date" source="paymentDueDate" />

          <ReferenceField label="School" source="schoolID" reference="schools">
            <TextField label="School" source="name" />
          </ReferenceField>
          <TextField label="Order Type" source="orderType" />
          <TextField labe="source" source="source" />
          <SelectInput
            label="Currency"
            source="currencyUsed"
            choices={[
              { id: "GBP", name: "GBP" },
              { id: "EUR", name: "EUR" },
              { id: "USD", name: "USD" },
              { id: "AUD", name: "AUD" },
              { id: "NZD", name: "NZD" },
              { id: "CAD", name: "CAD" }
            ]}
          />
          <ArrayInput source="items" label="Related Items">
            <SimpleFormIterator disableRemove disableAdd disableReordering>
              <FormDataConsumer>
                {({ _formData, scopedFormData, getSource }) => {
                  return (
                    <RelatedItemRow
                      getSource={getSource}
                      record={scopedFormData}
                    />
                  );
                }}
              </FormDataConsumer>
              <NumberInput label="Amount" source="amount" required />
              <BooleanInput label="Includes VAT" source="isVatIncluded" />
              <NumberInput label="VAT Percentage" source="vatPercentage" />
            </SimpleFormIterator>
          </ArrayInput>

          <DateField source="createdAt" />
          <DateField source="updatedAt" />
        </FormTab>
        <FormTab label="User info">
          <TextField source="buyerFirstName" />
          <TextField source="buyerLastName" />
          <TextField source="buyerEmail" />
          <TextField source="invoiceDetails.po" />
          <TextField source="invoiceDetails.customerAddress" />
        </FormTab>
        <FormTab label="Accouting">
          <NumberInput
            label="Obby Commission (%)"
            helperText="This value should include a 2.5 payment processing fee."
            source="commissionUsed"
            min={2.5}
          />
          <NumberField source="commissionUsed" />
          <BooleanField source="chargeCancellationFee" />
          <NumberField source="accounting.rawEarning" />
          <NumberField source="accounting.profitEarning" />
          <NumberField source="accounting.netPay" />
          <NumberField source="accounting.paymentProcessing" />
          <NumberField source="accounting.obbyMargin" />
          <NumberField source="accounting.percentageCommissionUsed" />
          <NumberField source="accounting.vat" />
          <NumberField source="accounting.obbyFee" />
          <NumberField source="accounting.discountAmountUsed" />
          <NumberField source="accounting.netTicketPrice" />
          <NumberField source="accounting.ticketVat" />
        </FormTab>
        <FormTab label="Payments plans">
          <BooleanField
            label="Is using a payment plan?"
            source="isUsingPaymentPlan"
          />
          <ArrayInput label="Payments plans" source="paymentPlan">
            <SimpleFormIterator>
              <TextInput label="id" source="id" />
              <TextInput label="Name" source="name" />
              <TextInput label="Amount" source="amount" />
              <DateInput label="Due Date" source="dueDate" />
              <BooleanInput label="Is paid" source="isPaid" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Purchase portions">
          <SelectInput
            label="Payout settings"
            source="payoutSettings"
            choices={[
              { id: "normal", name: "48 Hours After User Payment" },
              { id: "afterEvent", name: "48 Hours After Event Date" }
            ]}
          />
          <ReferenceArrayField
            reference="orderItemPurchases"
            source="purchasePortions"
            label=""
            fullWidth
          >
            <Datagrid>
              <ReferenceField
                label="ID"
                source="_id"
                reference="orderItemPurchases"
              >
                <TextField source="id" />
              </ReferenceField>
              <TextField source="amount"></TextField>
              <TextField source="userMoneyStatus" />
              <DateField source="payoutScheduleDate" />
              <TextField source="partnerMoneyStatus" />
              <DateField source="createdAt" />
            </Datagrid>
          </ReferenceArrayField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}
