import React from "react";
import {
  CardActions,
  Datagrid,
  DateField,
  Edit,
  ExportButton,
  Filter,
  List,
  RefreshButton,
  TextField,
  TextInput,
  NumberInput,
  DateTimeInput,
  TabbedForm,
  FormTab
} from "react-admin";
import { PapertrailReferenceTable } from "../components/PapertrailReferenceTable";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);
const MessageFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by name" source="q" alwaysOn />
  </Filter>
);

export const PrivateClassList = props => (
  <List
    {...props}
    filters={<MessageFilter />}
    sort={{ field: "createdAt", order: "DESC" }}
    actions={<PostActions />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="slug" />
      <TextField source="price" />
    </Datagrid>
  </List>
);

export const PrivateClassEdit = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="General">
        <TextInput disabled label="Id" source="id" />
        <TextInput label="Title" source="title" />
        <TextField label="Slug" source="slug" />
        <TextField label="schoolId" source="schoolId" />
        <TextInput label="address" source="address" />
        <NumberInput label="Price" source="price" disabled />
        <TextInput label="Student Count" source="studentCount" />
        <NumberInput label="Duration (min)" source="duration" />
        <DateTimeInput label="Start Date Time" source="startDateTime" />
        <DateField label="Created Date" source="createdAt" locales="en-GB" />
        <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
      </FormTab>
      <FormTab label="Papertrail">
        <PapertrailReferenceTable />
      </FormTab>
    </TabbedForm>
  </Edit>
);
