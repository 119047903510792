import React from "react";
import {
    BooleanInput,
    CardActions, Create, CreateButton, Datagrid, DateField, Edit, ExportButton, Filter, List, RefreshButton, SimpleForm, TextField, TextInput
} from "react-admin";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);

const CategoryFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by name" source="q" alwaysOn />
  </Filter>
);

export const CategoryList = props => (
  <List {...props} filters={<CategoryFilter />} actions={<PostActions />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="slug" />
    </Datagrid>
  </List>
);

export const CategoryCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <BooleanInput source="hidden" />
    </SimpleForm>
  </Create>
);

export const CategoryEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput label="Id" source="id" disabled />
      <TextInput source="name" />
      <TextInput source="slug" disabled />
      <BooleanInput label="Hidden category?" source="hidden" />
      <DateField label="Created Date" source="createdAt" locales="en-GB" />
      <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
    </SimpleForm>
  </Edit>
);
