import React from "react";
import {
  CardActions,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  ExportButton,
  Filter,
  FormTab,
  ImageField,
  List,
  NumberInput,
  ReferenceField,
  RefreshButton,
  ShowController,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  BooleanInput,
  NumberField,
  BooleanField,
  ArrayInput,
  SimpleFormIterator,
  DateInput,
  SelectInput
} from "react-admin";
import EditActions from "./../actions/giftcardEditActions";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);
const GiftcardFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by ID" source="qID" />
    <TextInput label="Search by recipien temail" source="qEmail" />
    <TextInput label="Search by buyer email" source="qBuyerEmail" />
    <TextInput label="Search by Discount ID" source="qDiscountID" />
    <TextInput
      label="Search by Stripe Payment ID"
      source="qStripePaymentIntentID"
      alwaysOn
    />
  </Filter>
);

export const GiftcardList = props => (
  <List
    {...props}
    filters={<GiftcardFilter />}
    sort={{ field: "createdAt", order: "DESC" }}
    actions={<PostActions />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="recipient" />
      <TextField source="sender" />
      <TextField source="message" />
      <TextField source="amount" />
      <TextField source="email" />
      <TextField source="buyerEmail" />
      <TextField source="buyerName" />
      <TextField source="deliveryMethod" />
    </Datagrid>
  </List>
);

export const GiftcardCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="deliveryMethod" />
      <TextInput source="recipient" />
      <TextInput source="sender" />
      <TextInput source="message" />
      <NumberInput source="amount" />
      <TextInput source="email" />
      <TextInput source="details" />
      <TextInput source="code" />
      <TextInput source="buyerEmail" />
      <TextInput source="buyerName" />
    </SimpleForm>
  </Create>
);
export const GiftcardEdit = props => (
  <ShowController {...props}>
    {({ record = {}, ...controllerProps }) => {
      console.info(record);
      return (
        <Edit
          {...props}
          record={record}
          {...controllerProps}
          actions={<EditActions />}
        >
          <TabbedForm>
            <FormTab label="General">
              <TextInput disabled label="Id" source="id" />
              <TextInput source="deliveryMethod" />
              <TextInput source="recipient" />
              <TextInput source="sender" />
              <TextInput source="message" />
              <TextInput source="email" />
              <TextInput source="buyerEmail" />
              <TextInput source="buyerName" />
              <TextField source="source" />
              <DateField
                label="Created Date"
                source="createdAt"
                locales="en-GB"
              />
              <DateField
                label="Updated Date"
                source="updatedAt"
                locales="en-GB"
              />
            </FormTab>
            <FormTab label="User Money">
              <NumberInput source="amount" />
              <TextField source="currencyUsed" />
              <TextField
                label="Stripe Transfer Group ID"
                source="stripeTransferGroupID"
              />
              {record.discountId && (
                <ReferenceField
                  label="Discount Id"
                  source="discountId"
                  reference="discounts"
                >
                  <TextField source="_id" />
                </ReferenceField>
              )}
            </FormTab>
            <FormTab label="Partner Money">
              <ReferenceField
                label="Payout Link"
                source="payoutID"
                reference="payouts"
              >
                <TextField source="id" />
              </ReferenceField>
              <TextField
                label="Stripe Transfer Group ID"
                source="stripeTransferGroupID"
              />
              <TextField
                label="Teacher money status"
                source="partnerMoneyStatus"
              />
              <BooleanInput
                label="Is this booking paid? (DEPRECATED)"
                source="isPaid"
              />
              <TextField
                label="payoutStripeID"
                source="partnerMoneyTransfer.payoutStripeID"
              />
              <TextField
                label="paymentStripeID"
                source="partnerMoneyTransfer.paymentStripeID"
              />
              <TextField
                label="balanceTransactionStripeID"
                source="partnerMoneyTransfer.balanceTransactionStripeID"
              />
              <TextField
                label="transferStripeID"
                source="partnerMoneyTransfer.transferStripeID"
              />
              <DateField
                label="transferDate"
                source="partnerMoneyTransfer.transferDate"
              />
              <NumberField
                label="transferAmount"
                source="partnerMoneyTransfer.transferAmount"
              />
              <BooleanField
                label="isReversed"
                source="partnerMoneyTransfer.isReversed"
              />
              <DateField
                label="reverseDate"
                source="partnerMoneyTransfer.reverseDate"
              />
              <NumberField
                label="reverseAmount"
                source="partnerMoneyTransfer.reverseAmount"
              />
              <TextField
                label="reverseStripeID"
                source="partnerMoneyTransfer.reverseStripeID"
              />
            </FormTab>
            <FormTab label="Accounting">
              <BooleanInput
                label="Charge Partner with a cancellation fee?"
                source="chargeCancellationFee"
              />
              <NumberInput
                label="Specify commission just for this booking"
                source="commissionUsed"
              />
              <NumberInput
                label="Sales earning (pence)"
                source="accounting.rawEarning"
              />
              <NumberInput
                label="Net total (pence)"
                source="accounting.netPay"
              />
              <NumberInput
                label="TeacherPayout (pence)"
                source="accounting.profitEarning"
              />
              <NumberInput
                label="User Charged"
                source="accounting.userCharged"
              />
              <NumberInput
                label="Discount amount used"
                source="accounting.discountAmountUsed"
              />
              <NumberInput
                label="Obby margin (pence)"
                source="accounting.obbyMargin"
              />
              <NumberInput
                label="Obby Fee(pence)"
                source="accounting.obbyFee"
              />
              <NumberInput
                label="Payment processing (pence)"
                source="accounting.paymentProcessing"
              />
              <NumberInput label="VAT (pence)" source="accounting.vat" />
              <NumberInput
                label="Commission used for the calculations (percentage)"
                source="accounting.percentageCommissionUsed"
              />
            </FormTab>
            <FormTab label="Used History">
              <ArrayInput source="usedHistory">
                <SimpleFormIterator>
                  <DateInput source="date" />
                  <NumberInput source="amount" />
                  <SelectInput
                    source="itemType"
                    choices={[
                      { id: "course", name: "course" },
                      { id: "product", name: "product" }
                    ]}
                  />
                  <TextInput source="itemID" />
                  <SelectInput
                    source="source"
                    choices={[
                      { id: "marketplace", name: "marketplace" },
                      { id: "widget", name: "widget" }
                    ]}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </FormTab>
            <FormTab label="address">
              <TextInput label="First Name" source="address.firstName" />
              <TextInput label="Last Name" source="address.lastName" />
              <TextInput label="Line 1" source="address.line1" />
              <TextInput label="Line 2" source="address.line2" />
              <TextInput label="Post code" source="address.postCode" />
              <TextInput label="City" source="address.city" />
            </FormTab>
            <FormTab label="Details">
              {record.details && record.details.courseId && (
                <ReferenceField
                  label="Course Id"
                  source="details.courseId"
                  reference="courses"
                >
                  <TextField label="Course Id" source="_id" />
                </ReferenceField>
              )}
              {record.details && record.details.title && (
                <TextField label="Course Title" source="details.title" />
              )}
              {record.details && record.details.url && (
                <TextField label="Course Url" source="details.url" />
              )}
              {record.details && record.details.cover && (
                <ImageField label="Cover" source="details.cover" />
              )}
            </FormTab>
          </TabbedForm>
        </Edit>
      );
    }}
  </ShowController>
);
