import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import PropTypes from "prop-types";
import React from "react";
import { CREATE, fetchEnd, showNotification } from "react-admin";
import { connect } from "react-redux";
import dataProvider from "./../dataProvider";

class AlgoliaShow extends React.Component {
  handleClick = () => {
    const { showNotification } = this.props;
    dataProvider(CREATE, "actions/update-algolia", {})
      .then(() => {
        showNotification(
          "Algolia will be updated. This process could take a few minutes."
        );
      })
      .catch(error => {
        showNotification(error.message, "error");
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  render() {
    return (
      <Card>
        <CardHeader title="Update Algolia" />
        <CardContent>
          <p>Click on the button below to update the results page.</p>
          <Button color="primary" onClick={this.handleClick}>
            Update Algolia
          </Button>
        </CardContent>
      </Card>
    );
  }
}

AlgoliaShow.propTypes = {
  showNotification: PropTypes.func
};

export default connect(null, {
  showNotification
})(AlgoliaShow);
