import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import IconCancel from "@material-ui/icons/Cancel";
import _ from "lodash";
import React, { Component, Fragment } from "react";
import {
  Button,
  CREATE,
  crudGetMatching,
  fetchEnd,
  fetchStart,
  SaveButton,
  showNotification
} from "react-admin";
import { connect } from "react-redux";
import { change, isSubmitting, submit } from "redux-form";
import dataProvider from "../dataProvider";

const CancelationReasonItems = [
  {
    label: "Unhappy client",
    value: "UNHAPPY_CLIENT"
  },
  {
    label: "Other",
    value: "OTHER"
  }
];

class RefundBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      showDialog: false,
      courseID: false,
      reason: "",
      cancellationMessage: "",
      sendCustomerEmail: false,
      isPartialRefund: false,
      partialRefundAmount: 0
    };
  }

  handleClick = () => {
    let reason = "";
    if (this.props.data.cancelled && this.props.data.cancelled.reason) {
      reason = this.props.data.cancelled.reason;
    }
    let cancellationMessage = "";
    if (
      this.props.data.cancelled &&
      this.props.data.cancelled.cancellationMessage
    ) {
      cancellationMessage = this.props.data.cancelled.cancellationMessage;
    }
    this.setState({
      showDialog: true,
      reason: reason,
      cancellationMessage: cancellationMessage
    });
  };

  handleCloseClick = () => {
    // reset the start date time
    this.setState({
      showDialog: false
    });
  };

  handleSubmitWithRedirect() {}

  handleSaveClick = () => {
    const { fetchStart, fetchEnd, showNotification } = this.props;
    fetchStart();
    let productPurchaseID = this.props.data.id;
    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    let reasonSelected = _.find(CancelationReasonItems, {
      value: this.state.reason
    });
    dataProvider(CREATE, "actions/refund-giftcard", {
      data: {
        productPurchaseID,
        reason: this.state.reason,
        reasonText: reasonSelected.label,
        cancellationMessage: this.state.cancellationMessage,
        sendCustomerEmail: this.state.sendCustomerEmail,
        isPartialRefund: this.state.isPartialRefund,
        partialRefundAmount: this.state.partialRefundAmount
      }
    })
      .then(({ data }) => {
        this.setState({ showDialog: false });
        //hacky way to reload the data
        window.location.reload();
      })
      .catch(error => {
        showNotification(error.message, "error");
      })
      .finally(() => {
        fetchEnd();
      });
  };

  handleReasonChange = e => {
    this.setState({
      reason: e.target.value
    });
  };

  handleCancellationMessage = e => {
    this.setState({
      cancellationMessage: e.target.value
    });
  };

  handleCustomerEmailOption = e => {
    this.setState({
      sendCustomerEmail: e.target.checked
    });
  };
  // disabled for now
  handleIsPartialRefund(e) {
    return;
    // this.setState({
    //   isPartialRefund: e.target.checked
    // });
  }
  handlePartialRefundAmount(e) {
    this.setState({
      partialRefundAmount: e.target.value
    });
  }

  render() {
    const { showDialog } = this.state;
    const { isSubmitting } = this.props;
    return (
      <Fragment>
        <Button onClick={this.handleClick} label="Refund Gifcard" />
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick}>
          <DialogTitle>Refund Giftcard</DialogTitle>
          <DialogContent>
            <div>
              <p>Refund reason</p>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.reason}
                onChange={this.handleReasonChange.bind(this)}
              >
                {CancelationReasonItems.map((reason, index) => {
                  return (
                    <MenuItem value={reason.value}>{reason.label}</MenuItem>
                  );
                })}
              </Select>
            </div>
            <div>
              <p>Cancellation message</p>
              <TextField
                value={this.state.cancellationMessage}
                onChange={this.handleCancellationMessage.bind(this)}
              />
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.sendCustomerEmail}
                  onChange={this.handleCustomerEmailOption}
                  name="school"
                  color="primary"
                />
              }
              label="Send customer email? (at the moment dont do anything)"
            />
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isPartialRefund}
                    onChange={this.handleIsPartialRefund.bind(this)}
                    name="school"
                    color="primary"
                  />
                }
                label="Is Partial Refund? (disabled due accouting)"
              />
              {this.state.isPartialRefund && (
                <TextField
                  label="Partial Amount"
                  type="number"
                  value={this.state.partialRefundAmount}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={this.handlePartialRefundAmount.bind(this)}
                />
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <SaveButton
              label="Refund Giftcard"
              saving={isSubmitting}
              onClick={this.handleSaveClick}
              handleSubmitWithRedirect={this.handleSubmitWithRedirect}
            />
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting("post-quick-create")(state)
});

const mapDispatchToProps = {
  change,
  crudGetMatching,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
};

export default connect(mapStateToProps, mapDispatchToProps)(RefundBooking);
