import React from "react";
import { CardActions } from "react-admin";
import AddBlockBookingStartDate from "./AddBlockBookingStartDate";
import CancelBooking from "./CancelBooking";
import RefundBooking from "./RefundBooking";
import RescheduleBooking from "./RescheduleBooking";
import ResendBookingEmail from "./ResendBookingEmail";
import RemoveGuestBooking from "./RemoveGuestBooking";
import PayBookingButton from "./PayBooking";

import { ResendBookingEmailsButton } from "./ResendBookingEmailsButton";

export default function BookingEditActions(props) {
  const { data: booking } = props;

  if (!booking) return <></>;

  return (
    <CardActions>
      <AddBlockBookingStartDate data={booking} />
      <RescheduleBooking data={booking} />
      <CancelBooking data={booking} />
      <ResendBookingEmail data={booking} />
      <RefundBooking data={booking} />
      <RemoveGuestBooking data={booking} />
      <PayBookingButton data={booking} />
      {booking.bookingType !== "private" && (
        <ResendBookingEmailsButton booking={booking} />
      )}
    </CardActions>
  );
}
