import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";

export function Link({
  resource,
  id,
  to = `/${resource}/${id}`,
  children = `#${id}`
}) {
  return (
    <ReactRouterLink to={to} onClick={e => e.stopPropagation()}>
      <span>{children}</span>
    </ReactRouterLink>
  );
}
