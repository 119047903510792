import React from "react";
import { Layout } from "react-admin";
import AdminMenu from "./AdminMenu";
import { AdminAppBar } from "./AdminAppBar";

const AdminLayout = props => (
  <Layout {...props} menu={AdminMenu} appBar={AdminAppBar} />
);

export default AdminLayout;
