import moment from "moment";
import React from "react";
import {
  Datagrid,
  DateField,
  Edit,
  Filter,
  FormTab,
  FunctionField,
  List,
  NumberInput,
  ReferenceField,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput
} from "react-admin";

const statusDisplay = {
  obby: "Obby Marketplace",
  widget: "Widget",
  manual: "Manually Entered"
};

export function WaitingListRecordFilters(props) {
  return (
    <Filter {...props}>
      <TextInput label="Search by ID" source="qID" />
      <TextInput label="Search by email" source="qEmail" alwaysOn />
      <TextInput label="Search by name" source="qName" />
      <SelectInput label="Filter by Source" source="qSource" choices={[{id: "obby", name: "Obby Marketplace"}, { id: "widget", name: "Widget"}, {id: "manual", name: "Manually Entered"}]} />
      <SelectInput label="Filter by Type" source="qType" choices={[{id: "session", name: "Session Availability Notification"}, { id: "course", name: "New Class Dates Notification"}, ]} />
    </Filter>
  );
}

export function WaitingListRecordList(props) {
  return (
    <List
      {...props}
      sort={{ field: "createdAt", order: "DESC" }}
      filters={<WaitingListRecordFilters />}
    >
      <Datagrid rowClick="edit">
        <TextField label="ID" source="_id" />
        <FunctionField
          label="Name"
          render={record => `${record.firstName} ${record.lastName}`}
        />
        <TextField label="Email" source="email" />
        <FunctionField
          label="Source"
          render={record => statusDisplay[record.source]}
        />
        <FunctionField
          label="Type"
          render={record =>
            record.type === "session"
              ? "Session Availability Notification"
              : "New Class Dates Notification"
          }
        />
      </Datagrid>
    </List>
  );
}

export function WaitingListRecordEdit(props) {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="Summary">
          <TextField label="ID" source="_id" disabled />
          <TextInput label="First Name" source="firstName" required />
          <TextInput label="Last Name" source="lastName" required />
          <TextInput label="Email" type="email" source="email" required />
          <TextInput label="Phone Number" type="tel" source="phone" />
          <NumberInput
            label="Number of Guests"
            source="numberOfGuests"
            min={1}
            required
          />
          <FunctionField
            label="Type"
            render={record =>
              record.type === "session"
                ? "Session Availability Notification"
                : "New Class Dates Notification"
            }
          />
          <ReferenceField label="Course" source="courseId" reference="courses">
            <FunctionField render={record => record.title} />
          </ReferenceField>

          <ReferenceField
            label="Session"
            source="sessionId"
            reference="sessions"
          >
            <FunctionField
              render={record => {
                return moment(record.startDateTime).format("DD/MM/YYYY h:mm A");
              }}
            />
          </ReferenceField>
          <DateField label="Enrolled Date" source="createdAt" />
          <DateField label="Last Sent Email" source="lastSent" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}
