import React from "react";
import {
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  ExportButton,
  Filter,
  FormTab,
  List,
  RefreshButton,
  TabbedForm,
  TextField,
  TextInput
} from "react-admin";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);

const AdminUserFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by ID" source="qID" />
    <TextInput label="Search by email" source="qEmail" alwaysOn />
    <TextInput label="Search by name" source="qName" />
  </Filter>
);

export const AdminUserList = props => (
  <List
    {...props}
    filters={<AdminUserFilter />}
    sort={{ field: "createdAt", order: "ASC" }}
    actions={<PostActions />}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="First Name" source="firstName" />
      <TextField label="Last Name" source="lastName" />
      <TextField label="Email" source="email" />
    </Datagrid>
  </List>
);

export const AdminUserEdit = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="User info">
        <TextField label="Id" source="id" disabled />
        <TextField label="Email" source="email" type="email" />
        <TextField label="First name" source="firstName" />
        <TextField label="Last name" source="lastName" />
        <DateField label="Created Date" source="createdAt" locales="en-GB" />
        <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
      </FormTab>
    </TabbedForm>
  </Edit>
);
