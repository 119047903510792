import React from "react";
import {
  ArrayInput,
  BooleanInput,
  CardActions,
  ChipField,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  ExportButton,
  Filter,
  FormTab,
  List,
  NumberInput,
  ReferenceArrayField,
  ReferenceField,
  RefreshButton,
  SelectInput,
  SimpleFormIterator,
  SingleFieldList,
  TabbedForm,
  TextField,
  TextInput,
  FormDataConsumer
} from "react-admin";

import ProductHideAction from "../actions/ProductHideAction";
import { PapertrailReferenceTable } from "../components/PapertrailReferenceTable";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);

function ProductActions(props) {
  return (
    <CardActions>
      <ProductHideAction data={props.data} />
    </CardActions>
  );
}

const ProductFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by name" source="qName" alwaysOn />
    <TextInput label="Search by Product ID" source="qID" />
    <TextInput label="Search by School ID" source="qSchoolID" />
    <TextInput label="Search by Product Slug" source="qSlug" />
  </Filter>
);

export const ProductList = props => (
  <List
    {...props}
    sort={{ field: "createdAt", order: "DESC" }}
    filters={<ProductFilter />}
    actions={<PostActions />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="slug" />
      <DateField
        source="createdAt"
        locales="en-GB"
        label="Created At"
        showTime
      />
    </Datagrid>
  </List>
);

export const ProductEdit = props => (
  <Edit {...props} actions={<ProductActions />}>
    <TabbedForm>
      <FormTab label="Summary">
        <TextInput disabled label="Id" source="id" />
        <TextInput source="name" />
        <TextInput disabled source="slug" />
        <ReferenceField label="School" source="school" reference="schools">
          <TextField source="name" />
        </ReferenceField>

        <FormDataConsumer>
          {({ formData }) => (
            <>
              <BooleanInput
                label="Show in Obby Marketplace"
                source="showInMarketplace"
              />
              <BooleanInput
                label="Hide from Algolia Results"
                source="hiddenFromResults"
                disabled={formData.showInMarketplace === false}
                format={val =>
                  formData.showInMarketplace === false ? true : val
                }
                helperText={
                  formData.showInMarketplace === false
                    ? "Only available when showing in the marketplace"
                    : undefined
                }
              />
              <BooleanInput
                label="Hide from Google"
                source="hidden"
                disabled={formData.showInMarketplace === false}
                format={val =>
                  formData.showInMarketplace === false ? true : val
                }
                helperText={
                  formData.showInMarketplace === false
                    ? "Only available when showing in the marketplace"
                    : undefined
                }
              />
              <BooleanInput
                label="Marked as sold out in Marketplace"
                source="isMarkedAsSoldOut"
              />
            </>
          )}
        </FormDataConsumer>
        <SelectInput
          label="Product type"
          source="productType"
          choices={[
            { id: "learning-kit", name: "learning-kit" },
            { id: "equipment", name: "equipment" },
            { id: "materials", name: "materials" },
            { id: "collection", name: "collection" },
            { id: "online-course", name: "online-course" }
          ]}
        />
        <TextInput source="category" />
        <TextInput source="level" />
        <TextInput source="shortDescription" fullWidth />
        <TextInput multiline source="description" fullWidth />
        <TextInput source="whatsIncluded" fullWidth />
        <NumberInput source="ageRestriction" />
        <TextInput source="cover" />
        <NumberInput source="price" />
        <NumberInput source="stock" />
        <TextInput source="purchaseMessage" fullWidth />
        <TextInput source="returnsPolicy" fullWidth />
        <ReferenceArrayField
          label="Fixed tags"
          reference="tags"
          source="fixedTags"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField label="CustomTags" reference="tags" source="tags">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField
          label="Private Widget Tags"
          reference="privateTags"
          source="privateTags"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField label="Created Date" source="createdAt" locales="en-GB" />
        <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
      </FormTab>
      <FormTab label="User Inputs">
        <h1>Old structure</h1>
        <ArrayInput source="userInputs">
          <SimpleFormIterator>
            <TextInput source="label" />
            <TextInput source="description" />
          </SimpleFormIterator>
        </ArrayInput>
        <h1>New Structure</h1>
        <BooleanInput
          source="userQuestionForm.isEnabled"
          label="Is user question form enabled?"
        />
        <SelectInput
          source="userQuestionForm.mode"
          choices={[
            { id: "all", name: "all" },
            { id: "oneAtTime", name: "oneAtTime" }
          ]}
        />
        <ArrayInput source="userQuestionForm.questions">
          <SimpleFormIterator>
            <TextInput
              source="name"
              label="Name identifier - dont use spaces"
            />
            <NumberInput
              min={0}
              source="order"
              label="The order of question please auto increment"
            />
            <TextInput source="title" />
            <BooleanInput source="isRequired" />
            <SelectInput
              source="typeOfInput"
              choices={[
                { id: "shortTextInput", name: "shortTextInput" },
                { id: "longTextInput", name: "longTextInput" },
                { id: "singleSelect", name: "singleSelect" },
                { id: "fileInput", name: "fileInput" },
                { id: "numberInput", name: "numberInput" },
                { id: "booleanInput", name: "booleanInput" },
                { id: "multiSelect", name: "multiSelect" }
              ]}
            />
            <TextInput
              source="postQuestionAnsweredTarget"
              label="Targeted question that the user will go if answer this one. Leave blanket to follow the normal order"
            />
            <ArrayInput source="singleSelectOptions">
              <SimpleFormIterator>
                <TextInput source="name" />
                <TextInput
                  source="postOptionSelectedTarget"
                  label="Name of question for conditional"
                />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Sorting/Flags">
        <BooleanInput source="featured" />
        <NumberInput source="slotOrder" />
      </FormTab>
      <FormTab label="Postage">
        <ReferenceArrayField
          label="Postage"
          reference="postage"
          source="postage"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
      </FormTab>
      <FormTab label="Gifting">
        <BooleanInput source="gifting.doTeacherWraps" />
        <BooleanInput sourc="gifting.isThereGiftingFee" />
        <NumberInput source="gifting.giftingFee" label="Gifting Fee (pence)" />
      </FormTab>
      <FormTab label="Learning Content">
        <ReferenceField label="Course" source="courses" reference="courses">
          <TextField source="title" />
        </ReferenceField>
        <ArrayInput source="learningContent">
          <SimpleFormIterator>
            <TextInput source="contentLink" />
            <TextInput source="contentType" />
            <TextInput source="instructionText" />
            <TextInput source="thumbnailURL" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="FAQ">
        <ArrayInput source="faq">
          <SimpleFormIterator>
            <TextInput source="question" />
            <TextInput source="answer" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="User Inputs">
        <ArrayInput source="userInputs">
          <SimpleFormIterator>
            <TextInput source="label" />
            <TextInput multiline source="description" fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Papertrail">
        <PapertrailReferenceTable />
      </FormTab>
    </TabbedForm>
  </Edit>
);
