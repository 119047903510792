import React from "react";
import {
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  ExportButton,
  List,
  RefreshButton,
  TextField,
  NumberField,
  BooleanField,
  NumberInput,
  TabbedForm,
  FormTab,
  ReferenceField,
  BooleanInput
} from "react-admin";
import EditActions from "./../actions/OrderItemActions/index";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);

export const OrderItemPurchaseList = props => (
  <List {...props} actions={<PostActions />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
    </Datagrid>
  </List>
);

export const OrderItemPurchaseEdit = props => (
  <Edit {...props} actions={<EditActions />}>
    <TabbedForm>
      <FormTab label="General">
        <TextField disabled label="Id" source="id" />
        <NumberInput source="amount" />
        <TextField source="userMoneyStatus" />
        <TextField source="partnerMoneyStatus" />
        <ReferenceField
          label="Order"
          source="order"
          reference="privatePayments"
        >
          <TextField source="_id" />
        </ReferenceField>
        <ReferenceField label="Payout" source="payout" reference="payouts">
          <TextField source="_id" />
        </ReferenceField>
        <DateField label="Created Date" source="createdAt" locales="en-GB" />
        <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
      </FormTab>
      <FormTab label={"User money status"}>
        <TextField source="userMoneyStatus" />
        <TextField source="refundedDetails.reason" />
        <TextField source="refundedDetails.message" />
      </FormTab>
      <FormTab label="PartnerMoney">
        <DateField source="payoutScheduleDate" />
        <ReferenceField
          label="Payout Link"
          source="payoutID"
          reference="payouts"
        >
          <TextField source="id" />
        </ReferenceField>
        <TextField
          label="Stripe Transfer Group ID"
          source="stripeTransferGroupID"
        />
        <TextField label="Teacher money status" source="partnerMoneyStatus" />
        <TextField
          label="Transfer Source mark Paid"
          source="paidOfflineSource"
        />
        <BooleanInput
          label="Is this booking paid? (DEPRECATED)"
          source="isPaid"
        />
        <TextField
          label="payoutStripeID"
          source="partnerMoneyTransfer.payoutStripeID"
        />
        <TextField
          label="paymentStripeID"
          source="partnerMoneyTransfer.paymentStripeID"
        />
        <TextField
          label="balanceTransactionStripeID"
          source="partnerMoneyTransfer.balanceTransactionStripeID"
        />
        <TextField
          label="transferStripeID"
          source="partnerMoneyTransfer.transferStripeID"
        />
        <DateField
          label="transferDate"
          source="partnerMoneyTransfer.transferDate"
        />
        <NumberField
          label="transferAmount"
          source="partnerMoneyTransfer.transferAmount"
        />
        <BooleanField
          label="isReversed"
          source="partnerMoneyTransfer.isReversed"
        />
        <DateField
          label="reverseDate"
          source="partnerMoneyTransfer.reverseDate"
        />
        <NumberField
          label="reverseAmount"
          source="partnerMoneyTransfer.reverseAmount"
        />
        <TextField
          label="reverseStripeID"
          source="partnerMoneyTransfer.reverseStripeID"
        />
      </FormTab>
      <FormTab label="Accouting">
        <NumberField source="accounting.rawEarning" />
        <NumberField source="accounting.profitEarning" />
        <NumberField source="accounting.netPay" />
        <NumberField source="accounting.paymentProcessing" />
        <NumberField source="accounting.obbyMargin" />
        <NumberField source="accounting.percentageCommissionUsed" />
        <NumberField source="accounting.vat" />
        <NumberField source="accounting.obbyFee" />
        <NumberField source="accounting.discountAmountUsed" />
        <NumberField source="accounting.netTicketPrice" />
        <NumberField source="accounting.ticketVat" />
      </FormTab>
    </TabbedForm>
  </Edit>
);
