import { AUTH_CHECK, AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT } from "react-admin";
import { auth } from "./config/auth";

export default function authProvider(type, params) {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    let apiDomain = process.env.REACT_APP_API
      ? process.env.REACT_APP_API
      : "http://localhost:3887";
    const request = new Request(`${apiDomain}/v1/auth/login`, {
      method: "POST",
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ "Content-Type": "application/json" })
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          return response.json().then(body => {
            throw new Error(body.message || response.statusText);
          });
        }
        return response.json();
      })
      .then(({ token, user }) => {
        auth.login(token, {
          id: user._id,
          name: user.fullName,
          avatar: user.profile.imageUrl
        });
      });
  }
  if (type === AUTH_LOGOUT) {
    auth.logout();
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401 || status === 403) {
      auth.logout();
      return Promise.reject();
    }
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return auth.isLoggedIn() ? Promise.resolve() : Promise.reject();
  }
  return Promise.resolve();
}
