import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { axios } from "../config/axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import {
  Button,
  fetchEnd,
  fetchStart,
  SaveButton,
  useNotify
} from "react-admin";

export function ResendBookingEmailsButton({ booking }) {
  const dispatch = useDispatch();

  const notify = useNotify();

  const [state, setState] = useState({
    emailToResend: "",
    isSubmitting: false,
    showDialog: false
  });
  const { emailToResend, isSubmitting, showDialog } = state;

  function onCloseDialog() {
    setState({ ...state, showDialog: false });
  }

  function onEmailToResendChange(e) {
    setState({
      ...state,
      emailToResend: e.target.value
    });
  }

  function onOpenDialog() {
    setState({ ...state, showDialog: true });
  }

  async function onSubmit() {
    dispatch(fetchStart());

    try {
      setState({ ...state, isSubmitting: true });
      await axios.post(`/actions/bookings/${booking._id}/resend-email`, {
        emailToResend
      });
      setState({
        ...state,
        emailToResend: "",
        isSubmitting: false,
        showDialog: false
      });
      notify("Email successfully resent", "success");
    } catch (e) {
      notify(e.message, "error");
      setState({ ...state, isSubmitting: false });
    } finally {
      dispatch(fetchEnd());
    }
  }

  return (
    <>
      <Button onClick={onOpenDialog} label="Resend emails" />
      <Dialog fullWidth open={showDialog} onClose={onCloseDialog}>
        <DialogTitle>Resend emails</DialogTitle>
        <DialogContent>
          <div>
            <div>Email to resend</div>
            <Select
              style={{ width: "230px" }}
              value={emailToResend}
              onChange={onEmailToResendChange}
            >
              <MenuItem value="booking_confirmation_emails">
                Booking confirmation emails
              </MenuItem>
              <MenuItem value="booking_reminder_email">
                Booking reminder email
              </MenuItem>
              <MenuItem value="post-class_review-email">
                Post-Class review email
              </MenuItem>
              <MenuItem value="booking_confirmation_send_partner">
                Send partner the alert booking email
              </MenuItem>
            </Select>
          </div>
        </DialogContent>
        <DialogActions>
          <SaveButton
            label="Submit"
            saving={isSubmitting}
            disabled={state.emailToResend === "" || isSubmitting}
            handleSubmitWithRedirect={onSubmit}
            icon={<></>}
          />
          <Button label="Cancel" onClick={onCloseDialog} />
        </DialogActions>
      </Dialog>
    </>
  );
}

// const mapStateToProps = state => ({
//   isSubmitting: isSubmitting("post-quick-create")(state)
// });
//
// const mapDispatchToProps = {
//   change,
//   crudGetMatching,
//   fetchEnd,
//   fetchStart,
//   showNotification,
//   submit
// };
//
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ResendBookingEmails);
