import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import PropTypes from "prop-types";
import React from "react";
import { showNotification } from "react-admin";
import { connect } from "react-redux";
import { axiosInstance } from "../config/axios";
import TextField from "@material-ui/core/TextField";

class RetryPaymentIntent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stripePaymentIntentID: ""
    };
    this.handleRetry = this.handleRetry.bind(this);
  }

  handleRetry = async () => {
    const { showNotification } = this.props;
    if (this.state.stripePaymentIntentID === "") {
      showNotification(`You need to add a Stripe PaymentIntentID`, "error");
      return;
    }
    try {
      showNotification(
        `We are retrying to process that payment intent ${this.state.stripePaymentIntentID}`
      );
      await axiosInstance.post("actions/retryPaymentIntent", {
        stripePaymentIntentID: this.state.stripePaymentIntentID
      });
      showNotification("Done");
    } catch (error) {
      showNotification(error.message, "error");
    }
  };

  render() {
    return (
      <Card>
        <CardHeader title="Retry processing for stripe payment intent" />
        <CardContent>
          <TextField
            required
            id="outlined-required"
            label="Required"
            any={this.state.stripePaymentIntentID}
            onChange={e => {
              console.log("e ", e);
              this.setState({ stripePaymentIntentID: e.target.value });
            }}
          />
          <Button color="primary" onClick={this.handleRetry}>
            Retry
          </Button>
        </CardContent>
      </Card>
    );
  }
}

RetryPaymentIntent.propTypes = {
  showNotification: PropTypes.func
};

export default connect(null, {
  showNotification
})(RetryPaymentIntent);
