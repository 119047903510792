import React from "react";
import {
  CardActions,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  ExportButton,
  Filter,
  List,
  RefreshButton,
  ArrayInput,
  SimpleFormIterator,
  TextField,
  TextInput,
  BooleanInput,
  NumberInput,
  FormDataConsumer,
  FormTab,
  TabbedForm,
  SelectInput,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
import { Button } from "@mui/material";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";

const BlockInstanceActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);
const BlockInstanceFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by name" source="q" alwaysOn />
  </Filter>
);

export const BlockInstanceList = props => (
  <List
    {...props}
    filters={<BlockInstanceFilter />}
    actions={<BlockInstanceActions />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <TextField source="name" />
    </Datagrid>
  </List>
);

export const BlockInstanceCreate = props => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="General">
          <TextInput source="name" label="Name" />
        </FormTab>
        <FormTab label="Data">
          <FormDataConsumer>
            {({ formData, ...inputProps }) => (
              <ArgsInput
                source="args"
                param={formData.params}
                inputProps={inputProps}
              />
            )}
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export function BlockInstanceEdit(props) {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="General">
          <TextInput disabled label="Id" source="id" />
          <TextField source="name" />
          <BooleanInput source="enabled" />
          <ReferenceInput
            label="Structure"
            source="structureId"
            reference="blockStructures"
            perPage={25}
            filterToQuery={searchText => ({ q: searchText })}
          >
            <AutocompleteInput
              optionText="name"
              allowEmpty={true}
              shouldRenderSuggestions={val => {
                return val.trim().length >= 2;
              }}
              highlightFirstSuggestion={false}
              inputValueMatcher={() => null}
              options={{
                fullWidth: true,
                suggestionsContainerProps: {
                  disablePortal: false
                }
              }}
            />
          </ReferenceInput>
          <DateField label="Created Date" source="createdAt" locales="en-GB" />
          <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
        </FormTab>
        <FormTab label="Data">
          <FormDataConsumer>
            {({ formData, ...inputProps }) => {
              return (
                formData.structure && (
                  <ArgsInput
                    source="value"
                    param={formData.structure.structure}
                    inputProps={inputProps}
                  />
                )
              );
            }}
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

function ArgsInput({ source, param = {}, label, inputProps = {} }) {
  if (param.type === "string")
    return <TextInput source={source} label={label} {...inputProps} />;
  if (param.type === "number")
    return <NumberInput source={source} label={label} {...inputProps} />;
  if (param.type === "boolean")
    return <BooleanInput source={source} label={label} {...inputProps} />;

  if (param.type === "enum") {
    return (
      <SelectInput
        source={source}
        choices={param.structure}
        label={label}
        {...inputProps}
      />
    );
  }

  if (param.type === "object")
    return param.structure.map(
      (property, index) =>
        property && (
          <div key={index}>
            <ArgsInput
              source={`${source}.${property.name}`}
              param={property}
              label={property.name}
              inputProps={inputProps}
            />
          </div>
        )
    );

  if (param.type === "array") {
    return (
      <ArrayInput source={source} label={param.name}>
        <SimpleFormIterator
          getItemLabel={() => ""}
          addButton={
            <Button label="Add">
              <AddIcon />
            </Button>
          }
          removeButton={
            <Button>
              <CloseIcon />
            </Button>
          }
        >
          <ArgsInput param={param.structure} inputProps={inputProps} />
        </SimpleFormIterator>
      </ArrayInput>
    );
  }

  return <></>;
}
