import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import PropTypes from "prop-types";
import React from "react";
import { showNotification } from "react-admin";
import { connect } from "react-redux";
import { axiosInstance } from "../config/axios";
import Dropzone from "react-dropzone";
import Papa from "papaparse";
// this will give a list of stripe payment intent that still need to be processed (created the booking etc.)

class FilterListOfStripePaymentIntentLackingProcess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileuploaded: false,
      stripePaymentIntentIDs: []
    };
    this.handleRetry = this.handleRetry.bind(this);
  }

  handleRetry = async () => {
    const { showNotification } = this.props;
    console.log("state ", this.state);
    if (this.state.stripePaymentIntentIDs.length === 0) {
      showNotification("We dont have any items on list to filter", "error");
      return;
    }
    try {
      showNotification(`We are filtering that list`);
      console.log("state ", this.state);
      const response = await axiosInstance.post(
        "actions/FilterListOfStripePaymentIntentLackingProcess",
        {
          stripePaymentIntentIDs: this.state.stripePaymentIntentIDs
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = `FilteredPaymentIntentNotprocessed-${+new Date()}.csv`;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      showNotification("Done");
    } catch (error) {
      showNotification(error.message, "error");
    }
  };
  handleUploadedFile = acceptedFiles => {
    const reader = new FileReader();
    reader.onload = () => {
      const { data, errors } = Papa.parse(reader.result, { delimiter: "," });
      if (errors.length > 1) {
        console.log("error ", errors);
        alert("There was an error, call a dev!");
      } else {
        console.log("data ", data);
        let items = [];
        if (data.length > 0) {
          for (let row of data) {
            let stripePaymentIntentID = row[0];
            console.log("stripePaymentIntentID ", stripePaymentIntentID);
            items.push(stripePaymentIntentID);
          }
          this.setState({
            stripePaymentIntentIDs: items,
            fileuploaded: true
          });
        }
      }
    };
    acceptedFiles.forEach(file => reader.readAsBinaryString(file));
  };

  render() {
    return (
      <Card>
        <CardHeader title="Filter the list of stripe payments intents that haven't been data processed (booking created) Disabled until we payments fix" />
        <CardContent>
          <Dropzone onDrop={this.handleUploadedFile}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>
                    Upload the CSV file with a list of Stripe PaymentIntentIDs
                    on first colument
                  </p>
                </div>
              </section>
            )}
          </Dropzone>
          <hr />
          <div>{this.state.fileuploaded ? "File uploaded" : "No file yet"}</div>
          <Button color="primary" onClick={this.handleRetry}>
            Filter list
          </Button>
        </CardContent>
      </Card>
    );
  }
}

FilterListOfStripePaymentIntentLackingProcess.propTypes = {
  showNotification: PropTypes.func
};

export default connect(null, {
  showNotification
})(FilterListOfStripePaymentIntentLackingProcess);
