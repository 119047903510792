import React from "react";
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Edit,
  Filter,
  FormTab,
  List,
  NumberField,
  TabbedForm,
  TextField,
  TextInput
} from "react-admin";
import { PapertrailReferenceTable } from "../components/PapertrailReferenceTable";

const PaymentListFilter = props => (
  <Filter {...props}>
    <TextInput
      label="Search by Stripe Payment Intent"
      source="qStripePaymentIntent"
      alwaysOn
    />
    <BooleanInput
      source="qShowOnlyNotProcessed"
      label="Display only not processed"
    />
    <BooleanInput source="qChargeProcessed" label="Has Charge Processed ?" />
    <BooleanInput source="qDataProcessed" label="Has Data Processed ?" />
    <BooleanInput
      source="qDataProcessedError"
      label="Data Data Processed Error ?"
    />
  </Filter>
);

export const PaymentIntentList = props => (
  <List
    {...props}
    filters={<PaymentListFilter />}
    ///actions={<PostActions />}
    sort={{ field: "createdAt", order: "DESC" }}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <DateField label="Update Date" source="updatedAt" locales="en-GB" />
      <BooleanField source="chargeProcessed" />
      <BooleanField source="dataProcessed" />
      <BooleanField label="Error" source="dataProcessedError" />
      <TextField source="stripePaymentIntentID" />
    </Datagrid>
  </List>
);

export const PaymentIntentEdit = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="Info">
        <TextField label="Id" source="id" disabled />
        <BooleanField source="isNewWorkerFlow" />
        <BooleanField source="chargeProcessed" />
        <BooleanField source="dataProcessed" />
        <BooleanField label="Error" source="dataProcessedError" />
        <BooleanInput source="dataErrorProcessedHandled" />
        <TextField source="stripePaymentIntentID" />
        <TextField
          source="status"
          label="status (deprecated) in new workerflow it dont used"
        />
        <BooleanField source="isFree" />
        <DateField label="Created Date" source="createdAt" locales="en-GB" />
        <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
      </FormTab>
      <FormTab label="Data">
        <TextField source="queryData" />
        <TextField source="dataProcessedResult" />
        <TextField source="destinationChargeData" />
        <TextField source="paymentIntentData" />
        <TextField source="bookingData" />
      </FormTab>
      <FormTab label="Subscription Data">
        <NumberField source="amount" />
        <BooleanField source="isSubscription" />
        <TextField source="subscriptionData" />
      </FormTab>
      <FormTab label="Processing Error">
        <TextField source="dataProcessedErrorResult" />
      </FormTab>

      <FormTab label="Papertrail">
        <PapertrailReferenceTable />
      </FormTab>
    </TabbedForm>
  </Edit>
);
