import React from "react";
import {
  BooleanInput,
  Datagrid,
  DateField,
  Edit,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput
} from "react-admin";

const ReviewFilters = props => (
  <Filter {...props}>
    <TextInput label="Search by name" source="name" alwaysOn />

    <SelectInput
      label="Filter by rating"
      source="rating"
      choices={[
        {
          id: 0,
          name: "0"
        },
        {
          id: 2,
          name: "1"
        },
        {
          id: 4,
          name: "2"
        },
        {
          id: 6,
          name: "3"
        },
        {
          id: 8,
          name: "4"
        },
        {
          id: 10,
          name: "5"
        }
      ]}
    />
    <SelectInput
      label="Filter by type"
      source="type"
      choices={[
        {
          id: "course",
          name: "Course"
        },
        {
          id: "product",
          name: "Product"
        }
      ]}
    />
    <TextInput label="Search by course/product" source="target" />
    <TextInput label="Search by school" source="school" />
  </Filter>
);

export const ReviewList = props => (
  <List
    {...props}
    sort={{ field: "createdAt", order: "DESC" }}
    filters={<ReviewFilters />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField label="Review" source="text" />
      <FunctionField
        label="Rating"
        render={record => <span>{record.value / 2}</span>}
      />
      <TargetReferenceField label="Course/Product" />
      <ReferenceField label="School" source="schoolId" reference="schools">
        <TextField source="name" />
      </ReferenceField>
      <DateField label="Date" source="createdAt" locales="en-GB" />
    </Datagrid>
  </List>
);

function TargetReferenceField(props) {
  const { record } = props;
  switch (record.targetType) {
    case "product":
      return (
        <ReferenceField
          label="Product"
          source="targetId"
          reference="products"
          {...props}
        >
          <FunctionField render={record => <span>{record.name}</span>} />
        </ReferenceField>
      );

    case "course":
      return (
        <ReferenceField
          label="Product"
          source="targetId"
          reference="courses"
          {...props}
        >
          <FunctionField render={record => <span>{record.title}</span>} />
        </ReferenceField>
      );
    default:
      return <span />;
  }
}

export const ReviewEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <BooleanInput source="showOnlyFirstName" />
      <TextInput label="Review" multiline fullWidth source="text" />
      <SelectInput
        label="Rating"
        source="value"
        choices={[
          {
            id: 0,
            name: "0"
          },
          {
            id: 2,
            name: " 1"
          },
          {
            id: 4,
            name: "2"
          },
          {
            id: 6,
            name: "3"
          },
          {
            id: 8,
            name: "4"
          },
          {
            id: 10,
            name: "5"
          }
        ]}
      />
      <TextField label="Course/Product" source="targetType" />
      <TargetReferenceField label="Course/Product" />
      <ReferenceField label="School" source="schoolId" reference="schools">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="User" source="userId" reference="users">
        <FunctionField
          render={record => (
            <span>
              {[record.profile.firstName, record.profile.lastName]
                .filter(name => name)
                .join(" ")}
            </span>
          )}
        />
      </ReferenceField>
      <DateField label="Created at" source="createdAt" locales="en-GB" />
      <DateField label="Updated at" source="updatedAt" locales="en-GB" />
    </SimpleForm>
  </Edit>
);
