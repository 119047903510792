import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Papa from "papaparse";
import PropTypes from "prop-types";
import React from "react";
import { CREATE, fetchEnd, showNotification } from "react-admin";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import dataProvider from "./../dataProvider";

class UploadIDS extends React.Component {
  constructor(props) {
    super(props);
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.state = {
      fileuploaded: false,
      ids: []
    };
    this.handleUploadedFile = this.handleUploadedFile.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  handleUpload = () => {
    const { showNotification } = this.props;
    dataProvider(CREATE, "actions/make-bookings-paid", {
      data: {
        ids: this.state.ids
      }
    })
      .then(() => {
        showNotification("Marked the bookings and product as paid!");
      })
      .catch(error => {
        showNotification(error.message, "error");
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  handleUploadedFile = acceptedFiles => {
    const reader = new FileReader();
    reader.onload = () => {
      const { data, errors } = Papa.parse(reader.result, { delimiter: "," });
      if (errors.length > 1) {
        console.log("error ", errors);
        alert("There was an error, call a dev!");
      } else {
        let ids = [];
        if (data.length > 1) {
          data.shift();
          for (let item of data) {
            ids.push(item[0]);
          }
          this.setState({
            ids: ids,
            fileuploaded: true
          });
        }
      }
    };
    acceptedFiles.forEach(file => reader.readAsBinaryString(file));
  };

  render() {
    return (
      <Card>
        <CardHeader title="Upload bookings/products purchases and giftcards IDS" />
        <CardContent>
          <Dropzone onDrop={this.handleUploadedFile}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>
                    Upload the CSV file with bookings and products ids by
                    clicking here and press save button below
                  </p>
                </div>
              </section>
            )}
          </Dropzone>
          <hr />
          <div>{this.state.fileuploaded ? "File uploaded" : ""}</div>
          <hr />
          <Button
            color="primary"
            onClick={this.handleUpload}
            disabled={!this.state.fileuploaded}
          >
            Save bookings as products as paid
          </Button>
        </CardContent>
      </Card>
    );
  }
}

UploadIDS.propTypes = {
  showNotification: PropTypes.func
};

export default connect(null, {
  showNotification
})(UploadIDS);
