import PropTypes from "prop-types";
import React from "react";
import { showNotification } from "react-admin";
import { connect } from "react-redux";
import AlgoliaComponent from "./algolia";
import ExtractComponnent from "./extract";
import ExtractBankDetails from "./extractBankDetails";
import UploadIDS from "./uploadIDS";
import VatReport from "./vatReport";
import UploadSchoolsPayouts from "./uploadSchoolsPayouts";
import ResetDatabaseToSnapShot from "./ResetDatabaseToSnapShot";
import ExtractGiftcards from "./extractGiftcards";
import RetryPaymentIntent from "./retryPaymentIntent";
import FilterListOfStripePaymentIntentLackingProcess from "./FilterListOfStripePaymentIntentLackingProcess";
class Dashboard extends React.Component {
  render() {
    return (
      <div>
        <ExtractComponnent />
        <hr />
        <ExtractGiftcards />
        <hr />
        <VatReport />
        <hr />
        <RetryPaymentIntent />
        <hr />
        <FilterListOfStripePaymentIntentLackingProcess />
        <hr />
        <ExtractBankDetails />
        <hr />
        <UploadSchoolsPayouts />
        <hr />
        <UploadIDS />
        <hr />
        <AlgoliaComponent />
        <hr />
        {process.env.REACT_APP_VERCEL_ENV &&
          process.env.REACT_APP_VERCEL_ENV === "preview" && (
            <ResetDatabaseToSnapShot />
          )}
      </div>
    );
  }
}

Dashboard.propTypes = {
  showNotification: PropTypes.func
};

export default connect(null, {
  showNotification
})(Dashboard);
