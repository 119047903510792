import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import IconCancel from "@material-ui/icons/Cancel";
import _ from "lodash";
import { DateTimePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import moment from "moment-timezone";
import React, { Component, Fragment } from "react";
import {
  Button,
  CREATE,
  crudGetMatching,
  fetchEnd,
  fetchStart,
  GET_ONE,
  SaveButton,
  showNotification
} from "react-admin";
import { connect } from "react-redux";
import { change, isSubmitting, submit } from "redux-form";
import dataProvider from "../dataProvider";

class CancelBookingButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      showDialog: false,
      startDateTime: new Date(),
      sessionID: false,
      bookingType: "regular",
      gotData: false,
      updatedCourse: {},
      courseID: false
    };
    this.renderBookingDates = this.renderBookingDates.bind(this);
    this.renderRegularDates = this.renderRegularDates.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (typeof nextProps.data != "undefined") {
      if (nextProps.data.bookingType === "private") {
        return { bookingType: nextProps.data.bookingType };
      } else {
        return {
          bookingType: nextProps.data.bookingType,
          courseID: nextProps.data.course._id
        };
      }
    }
  }

  async componentDidUpdate() {
    let isEmpty =
      Object.keys(this.state.updatedCourse).length === 0 &&
      this.state.updatedCourse.constructor === Object;
    if (isEmpty) {
      let serverCourse = await dataProvider(GET_ONE, `courses`, {
        id: this.state.courseID
      });
      this.setState({
        updatedCourse: serverCourse.data,
        gotData: true
      });
    }
  }

  handleClick = () => {
    let startDateTime = this.state.startDateTime;
    if (!startDateTime) {
      if (
        typeof this.props.data != "undefined" &&
        typeof this.props.data.session != "undefined"
      ) {
        startDateTime = this.props.data.session.startDateTime;
      }
    }
    this.setState({
      showDialog: true,
      startDateTime: startDateTime
    });
  };

  handleCloseClick = () => {
    let startDateTime = this.state.startDateTime;
    if (
      typeof this.props.data != "undefined" &&
      typeof this.props.data.session != "undefined"
    ) {
      startDateTime = this.props.data.session.startDateTime;
    }
    // reset the start date time
    this.setState({
      showDialog: false,
      startDateTime: startDateTime
    });
  };

  handleDateChange = date => {
    this.setState({ startDateTime: date });
  };

  handleSaveClick = () => {
    const { fetchStart, fetchEnd, showNotification } = this.props;
    fetchStart();
    let bookingID = this.props.data.id;
    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider(CREATE, "actions/booking/adminRescheduleBooking", {
      data: {
        bookingID,
        startDateTime: this.state.startDateTime,
        sessionID: this.state.sessionID
      }
    })
      .then(({ data }) => {
        this.setState({ showDialog: false });
        //hacky way to reload the data
        window.location.reload();
      })
      .catch(error => {
        showNotification(error.message, "error");
      })
      .finally(() => {
        fetchEnd();
      });
  };

  renderBookingDates() {
    if (!this.state.gotData) return;
    if (this.state.bookingType === "regular") {
      return this.renderRegularDates(this.state.updatedCourse.renderSessions);
    }
    if (this.state.bookingType === "anytime") {
      return this.renderAnytimeDates(this.state.updatedCourse.anytimeClass);
    }
    if (
      this.state.bookingType === "block" ||
      this.state.bookingType === "private"
    ) {
      return null;
    }
  }

  generateTimes(anytimeClass) {
    var result = [];
    if (typeof anytimeClass.times == "undefined") return result;
    anytimeClass.times.forEach(function(time) {
      for (var i = 0; i < 24; i++) {
        for (var j = 0; j < 2; j++) {
          let minutes = i * 60 + j * 30;
          if (minutes >= time.start && minutes <= time.end) {
            let startTime = i * 60 + j * 30;
            let endTime = startTime + anytimeClass.duration;
            let startTimeLabel =
              (i < 10 ? "0" + i : i) + ":" + (j === 0 ? "00" : j * 30);
            let endTimeLabel =
              parseInt(endTime / 60) +
              (parseInt(endTime / 60) === 0 ? "0" : "") +
              ":" +
              (endTime % 60) +
              (endTime % 60 === 0 ? "0" : "");
            result.push({
              value: startTime,
              label: startTimeLabel + " to " + endTimeLabel
            });
          }
        }
      }
    });
    return result;
  }

  renderAnytimeDates(anytimeClass = {}) {
    let timesAvailable = this.generateTimes(anytimeClass);
    return (
      <div>
        <h4>
          This is anytime class. Here are some days and times that are
          available:
        </h4>
        {typeof anytimeClass.days != "undefined" &&
          Object.keys(anytimeClass.days).map(key => {
            return (
              <FormControlLabel
                color="primary"
                label={key}
                control={
                  <Checkbox checked={anytimeClass.days[key]} color="primary" />
                }
              />
            );
          })}
        <hr />
        {timesAvailable.map(time => {
          return <TextField defaultValue={time.label} />;
        })}
      </div>
    );
  }

  renderRegularDates(sessions = []) {
    let availableDates = [];
    console.log("the sessions are ", sessions);
    if (sessions.length > 0) {
      let FilteravailableDates = sessions
        .filter(session => moment(session.startDateTime).isAfter())
        .filter(session => {
          let isAvailable =
            session.availability > 0 &&
            this.props.data.totalAttendants <= session.availability;
          return isAvailable;
        });

      availableDates = _.sortBy(FilteravailableDates, session => {
        return session.startDateTime;
      });
    }
    return (
      <div>
        <h5>
          This is regular booking please choose, if you want to, from the
          available dates below:{" "}
        </h5>
        {availableDates.map(date => {
          return (
            <Chip
              label={moment(date.startDateTime)
                .tz("Europe/London")
                .format()}
              onClick={() => {
                this.setState({
                  startDateTime: date.startDateTime,
                  sessionID: date._id
                });
              }}
              color="primary"
            />
          );
        })}
      </div>
    );
  }

  render() {
    const { showDialog } = this.state;
    const { isSubmitting } = this.props;
    return (
      <Fragment>
        <Button onClick={this.handleClick} label="Reschedule booking" />
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Create post"
        >
          <DialogTitle>Reschedule booking</DialogTitle>
          <DialogContent>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                value={this.state.startDateTime}
                label="Date for Session"
                disable={true}
                disablePast={true}
                onChange={this.handleDateChange}
              />
            </MuiPickersUtilsProvider>
            {this.renderBookingDates()}
          </DialogContent>
          <DialogActions>
            <SaveButton
              label="Reschedule booking"
              saving={isSubmitting}
              handleSubmitWithRedirect={this.handleSaveClick}
            />
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting("post-quick-create")(state)
});

const mapDispatchToProps = {
  change,
  crudGetMatching,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelBookingButton);
