import axios from "axios";
import { auth } from "./auth";

let headers = {
  "Content-Type": "application/json"
};
let axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API}/v1`,
  headers: { common: headers }
});
axiosInstance.interceptors.request.use(config => {
  // Dynamically set the authorization header on each request
  const token = auth.getToken();
  config.headers.common.Authorization = `Bearer ${token}`;
  return config;
});
export { axiosInstance };
export { axiosInstance as axios };
