import React from "react";
import { CardActions } from "react-admin";
import RefundOrderItem from "./RefundOrderItem";

export default function OrderEditActions(props) {
  const { data: orderItem } = props;
  console.log("props ", props);

  if (!orderItem) return <></>;

  return (
    <CardActions>
      <RefundOrderItem data={orderItem} />
    </CardActions>
  );
}
